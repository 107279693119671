<template>
  <section class="filesForm">
    <h3>Adicione arquivos para seu assistente</h3>
    <form 
      @drop.prevent="handleDrop"
      @dragover.prevent="handleDrag($event,'over')"
      @dragleave.prevent="handleDrag($event,'out')"
      :class="{ hover: hover}"
    >
      <div>
        <table v-if="arquivos.length > 0 || fakeFiles.length > 0" class="filesList">
          <thead>
            <tr>
              <th>Arquivo</th>
              <th>Tamanho</th>
              <th>Data</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(arquivo, i) in arquivos" :key="arquivo">
              <td>{{ parseFileName(arquivo.fileName) }}</td>
              <td>{{ translateFileSize(arquivo.size) }}</td>
              <td>{{ formatDateTime(arquivo.createdAt) }}</td>
              <td class="action">
                <mini-spinner v-if="arquivo.removing"/>
                <a href="#" @click.prevent="removeItem(false,i, arquivo.idAssistantFile)" v-else>
                  <fa :icon="['far','trash-can']" />
                </a>
              </td>
            </tr>
            <template v-if="fakeFiles.length > 0">
              <tr v-for="(file,i) in fakeFiles" :key="file" class="upload">
                <td>{{ parseFileName(file.name) }}</td>
                <td>{{ translateFileSize(file.size) }}</td>
                <td>{{ convertUnix(file.lastModified) }}</td>
                <td class="action">
                  <mini-spinner v-if="file.removing || saving"/>
                  <a href="#" @click.prevent="removeItem(true,i)" v-else>
                    <fa :icon="['far','trash-can']" />
                  </a>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <label :class="['inputMultiple', { disabled: saving }]">
          <div class="icWrap" v-if="arquivos.length == 0 && fakeFiles.length == 0">
            <fa :icon="['fal', 'images']"  class="ic"/>
          </div>
          <input 
            type="file" 
            multiple 
            @change="handleFiles" 
            ref="fileInput"
            accept=".c,.cpp,.css,.csv,.docx,.gif,.go,.html,.java,.jpeg,.jpg,.js,.json,.md,.pdf,.php,.pkl,.png,.pptx,.py,.rb,.tar,.tex,.ts,.txt,.webp,.xlsx,.xml,.zip"
          />
          <h4 v-if="arquivos.length == 0 && fakeFiles.length == 0">Arraste seus arquivos aqui ou <span class="color-primary">clique</span> para fazer o upload</h4>
          <div v-else class="addMore">
            + Adicionar arquivos
          </div>
          <div class="overload" v-if="overload"> 
            <p>Você só pode subir <b>100mb</b> de arquivos por vez. Escolha um arquivo menor ou remova alguns arquivos para continuar.</p>
          </div>
        </label>
      </div>
    </form>
    <footer>
      <a href="#" class="btn btn-primary" @click.prevent="addFilesToVector()" :disabled="saving">Anexar arquivos</a>
    </footer>
  </section>
</template>

<script>
import EstudAIService from '@/services/estudai.service.js'
import { formatDateTime, convertUnix, translateFileSize } from '@/services/utils.service.js'
import MiniSpinner from '@/components/utilities/MiniSpinner.vue'

export default {
  components: { 
    MiniSpinner
  },
  props: {
    assistant: {
      type: Object
    }
  },
  watch: {
    assistant ( value ) {
      if ( value ) {
        this.arquivos = []
      }
    }
  },
  data () {
    return {
      files: null,
      arquivos: [],
      fakeFiles: [],
      hover: false,
      saving: false,
      allowed: [
        ".c", ".cpp", ".css", ".csv", ".docx", ".gif", ".go", ".html", ".java", 
        ".jpeg", ".jpg", ".js", ".json", ".md", ".pdf", ".php", ".pkl", ".png", 
        ".pptx", ".py", ".rb", ".tar", ".tex", ".ts", ".txt", ".webp", ".xlsx", 
        ".xml", ".zip"
      ],
      overload: false,
      limit: 100,
    }
  },
  mounted () {
    this.getFilesFromVector()
  },
  methods: {
    handleFiles( e ) {
      if ( e ) {
        this.files = e.target.files
      } else {
        const selectedFiles = this.$refs.fileInput.files
        this.files = [...selectedFiles]
      }

      this.fakeFiles = []
      const maxSize = 100 * 1024 * 1024
      const totalSize = this.files.reduce((sum, file) => sum + file.size, 0)
      console.log("totalSize:", totalSize)

      if ( totalSize <= maxSize ) {
        this.files.forEach( k => {
          const ext = `.${ k.name.split('.').pop() }`
          console.log("ext:", ext)
          
          if ( this.allowed.includes(ext) ) {
            this.fakeFiles.push({
              name: k.name,
              size: k.size,
              lastModified: k.lastModified
            })
          } else {
            window.toaster.emit('open', {style: "error", message: "Arquivo não permitido. Utilize arquivos com extensões: " + this.allowed.join(', '), floater: true})
          }
        })

        this.overload = false
      } else {
        this.overload = true
      }
      
    },
    handleDrag ( e, state ) {
      e.preventDefault()

      this.hover = state == 'over'
    },
    handleDrop ( e ) {
      if ( !this.saving ) {
        const droppedFiles = e.dataTransfer.files
        this.files = [...droppedFiles]
        // Manually trigger the input's change event if needed
        this.$refs.fileInput.files = droppedFiles
        this.handleFiles()
        this.hover = false
      }
      
    },
    getFilesFromVector () {
      EstudAIService.getFilesFromVector(this.assistant.idAssistant).then(
        response => {
          console.log("getFilesFromVector:", response.data)
          if ( response.data.length > 0) {
            this.arquivos = response.data
          }
        },
        error => {
          console.error(error)
        }
      )
    },
    addFilesToVector () {
      const formData = new FormData()
      
      this.files.forEach( k => {
        formData.append('files[]', k)
      })
      
      formData.append('_token', '{{ csrf_token() }}')

      this.saving = true

      EstudAIService.addFilesToVector(this.assistant.idAssistant, formData).then(
        response => {
          console.log("addFilestoVector:", response.data)
          this.saving = false
          this.fakeFiles = []
          this.getFilesFromVector()
        },
        error => {
          console.error(error)
          this.saving = false
        }
      )
    },
    formatDateTime ( date ) {
      return formatDateTime(date, null, true)
    },
    convertUnix ( unix ) {
      console.log("convertUnix:", unix)
      const drop = formatDateTime(convertUnix(unix, false), false, true)
      console.log("drop:", drop)

      return drop
    },
    translateFileSize ( fileSize ) {
      return translateFileSize(fileSize)
    },
    parseFileName( name ) {
      const split = name.split('.')
      const ext = split.pop()
      var title = split[0]
          title = title.length > 20  ? title.substring(0, 20) + '(...)' : title

      return title +'.'+ ext
    },
    removeItem (fake, i, item ) {
      if ( fake ) {
        this.fakeFiles[i].removing = true
        
        setTimeout(() => {
          this.files.splice(i, 1)
          this.fakeFiles.splice(i, 1)
        },500)
      } else {
        this.arquivos[i].removing = true
        EstudAIService.removeFilesFromVector( item ).then(
          response => {
            if ( response.status == 200 ) {
              this.arquivos.splice(i, 1)
              this.arquivos[i].removing = false
            }
          },
          error => {
            console.error(error)
            this.arquivos[i].removing = false
          }
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .filesForm {
    h3 {
      text-align: center;
      padding: $mg_mid 0 0 0;
    }
  }
  form {
    display: block;
    padding: $hmg_mid;
    position: relative;

    &.hover {
      > div {
        border-radius: $border-radius;
        border: 2px dashed $color-success-2
      }
    }
  }
  footer {
    width: 100%;
    padding: $hmg_mid;
    border-top: $border-component;
    display: flex; align-items: center;
    justify-content: flex-end
  }
  .filesList {
    width: 100%;
    padding: 0; margin: 0;
    border: 0; border-collapse: collapse;
    margin: $hmg_mid 0;

    th,td {
      padding: $mg_mini;
      border-bottom: $border-component;

      // &:first-child{padding-left: 0;}
      // &:last-child{padding-right: 0;}

      &.action {
        text-align: right;
        font-size: 16px;

        a {
          &:hover {
            color: $color-primary;
          }
        }
      }
    }

    th {
      font-size: 10px; font-weight: bold;
    }

    .upload {
      td {
        background-color: $color-credito
      }
    }
  }

  .inputMultiple {
    width: 100%;

    &.disabled {
      opacity: .7;
      pointer-events: none;
    }

    .icWrap {
      height: 140px;
      margin: 0 auto $hmg_mid;
      text-align: center;
      display: flex;

      .ic {
        font-size: 84px;
        transform-origin: center;
        transform: rotate(-45deg);
        margin: auto
      }
    }
    
    .addMore { 
      width: 100%; display: flex;
      align-items: center; align-content: center;
      justify-content: center;
      gap: $hmg_mini;
      border-radius: $border-radius-small;
      background-color: $color-bg;
      text-align: center;
      padding: $mg_mini
    }
    
    input {
      appearance: none;
      opacity: 0; pointer-events: none;
      z-index: -1; position: absolute;
    }
    h4 {
      text-align: center;
      max-width: 80%;
      margin: auto
    }
  }

  .overload {
    margin-top: $mg_mini;
    background-color: $color-warning;
    padding: $mg_mini;
    border-radius: $border-radius-small;
    text-align: center;
    color: $color-secondary;

    p {margin: 0; padding: 0; text-align: center;}
  }
</style>





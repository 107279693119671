<template>
  <section class="mainBanner">
    <Flicking :options="{ circular: true, panelsPerView: 1, defaultIndex: 1 }" :plugins="plugins">
      <div 
        v-for="(item,i) in list"
        :class="['slide', 'flicking-panel']"
        :key="i"
        :style="{backgroundImage: 'url(' + slideBG(item) + ')'}"
        @click="gotoRoute(item)"
      ></div>
      <template #viewport>
        <span class="flicking-arrow-prev"></span>
        <span class="flicking-arrow-next"></span>
      </template>
    </Flicking>
  </section>
</template>

<script>
import Flicking from "@egjs/vue3-flicking";

// import "@egjs/flicking-plugins/dist/arrow.css";
import { AutoPlay, Arrow } from "@egjs/flicking-plugins"

const plugins = [new AutoPlay({ duration: 5000, direction: "NEXT", stopOnHover: true }), new Arrow()]

export default {
  components: {
    Flicking
  },
  data() {
    return {
      plugins,
      list: [
        {
          title: "Programa Embaixadores",
          text: "",
          image: process.env.VUE_APP_API_URL_PUBLIC + "marketplace/comunicacao/banner_mp_embaixadores.png",
          image_m: process.env.VUE_APP_API_URL_PUBLIC + "marketplace/comunicacao/banner_mp_embaixadores.png",
          link: '/loja/categoria/5/Uniforme%20escolar',
          url: 'https://lp.saberemrede.net/rededecisao'
        },
        {
          title: "Curso Técnico 2025",
          text: "",
          image: process.env.VUE_APP_API_URL_PUBLIC + "marketplace/comunicacao/tecnico_25.png",
          image_m: process.env.VUE_APP_API_URL_PUBLIC + "marketplace/comunicacao/tecnico_25.png",
          link: '/loja/categoria/2/Cursos%20Extras'
        },
        {
          title: "NCE 2025",
          text: "",
          image: process.env.VUE_APP_API_URL_PUBLIC + "marketplace/comunicacao/nce_2025.png",
          image_m: process.env.VUE_APP_API_URL_PUBLIC + "marketplace/comunicacao/nce_2025.png",
          link: '/loja/categoria/2/Cursos%20Extras'
        },
        {
          title: "Festa de Encerramento",
          text: "",
          image: process.env.VUE_APP_API_URL_PUBLIC + "marketplace/comunicacao/festa_encerramento_24.png",
          image_m: process.env.VUE_APP_API_URL_PUBLIC + "marketplace/comunicacao/festa_encerramento_24.png",
          link: '/loja/categoria/9/Festas%20e%20Eventos'
        }
      ]
    }
  },
  mounted () {
    
  },
  methods: {
    slideBG ( item ) {
      return window.innerWidth < 1024 ? item.image_m : item.image
    },
    
    gotoRoute( route ) {
      if ( route ) {
        if ( route.url ) {
          window.open(route.url)
        } else {
          this.$router.push(route.link)
        }
        
      }
    }
  }
}
</script>
<style lang="scss">
@import 'https://unpkg.com/@egjs/flicking/dist/flicking.css';
@import 'https://unpkg.com/@egjs/flicking/dist/flicking-inline.css';
@import '@egjs/flicking-plugins/dist/arrow.css';

  .mainBanner {
    height: calc(100vh - 218px); width: 100%; min-height: 800px;
    background-color: $color-secondary;

    .slide {
      width: 100%;
      background-position: center;
      background-position: $color-secondary;
      background-repeat: no-repeat;
      background-size: cover;
    }

    @media screen and ( max-width: 1220px ) {
      height: 100vw; max-height: 50vh;
      min-height: 0;
    }
  }

  
  .flicking-arrow-prev {
    left: 20px !important
  }
  .flicking-arrow-next {
    right: 20px !important
  }

  .flicking-arrow-prev,
  .flicking-arrow-next {
    border-radius: 100%;
    background-color: $color-secondary !important;
  }

  .flicking-arrow-prev::before, 
  .flicking-arrow-prev::after, 
  .flicking-arrow-next::before, 
  .flicking-arrow-next::after {
    background-color: $color-primary !important;
  }

  @media screen and (max-width: 680px) {
    .flicking-arrow-prev,
    .flicking-arrow-next {
      display: none;
    }
  }
</style>
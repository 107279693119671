import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createStore } from 'vuex'
import { auth } from './store/auth.module'
import { checkout } from './store/loja.module'
import { matricula, desconto } from './store/matricula.module'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import { createPinia } from 'pinia'

import '@/assets/scss/style.scss'

import {
  faSignInAlt,
  faSignOutAlt,
  faRightLong,
  faArrowRightLong,
  faEye,
  faEyeSlash,
  faPencil,
  faTrashCan,
  faCog,
  faGraduationCap,
  faCircleDollar,
  faStore,
  faUserGraduate,
  faBookReader,
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
  faShuffle,
  faFileCertificate,
  faUserPen,
  faBarcodeRead,
  faMoneyBillWave,
  faFileInvoiceDollar,
  faBars,
  faUsers,
  faHomeLgAlt,
  faUserHeadset,
  faCheck,
  faArrowRight,
  faLeftLong,
  faArrowUp,
  faUpRightFromSquare,
  faMagnifyingGlass as faMagnifyingGlassReg,
  faSquarePollVertical,
  faBarsSort,
  faFaceSmilePlus as faFaceSmilePlusRegular,
  faArrowUpRightFromSquare,
  faFileSignature as faFileSignatureRegular,
  faSignature,
  faSignatureSlash,
  faGroupArrowsRotate,
  faArrowsRotate as faArrowsRotateRegular,
  faArrowLeftLong,
  faSchoolFlag as faSchoolFlagRegular,
  faChalkboardUser,
  faWrench,
  faClock as faClockRegular,
  faArrowDownWideShort,
  faBadgePercent as faBadgePercentRegular,
  faCartXmark,
  faLinkSimple as faLinkSimpleRegular,
  faLaptopCode as faLaptopCodeRegular,
  faArrowUpRightFromSquare as faArrowUpRightFromSquareRegular,
  faUserTieHair as faUserTieHairRegular,
  faUser as faUserRegular,
  faEnvelopeCircleCheck,
  faTrashXmark as faTrashXmarkRegular,
  faXmark as faXmarkRegular,
  faCircleCheck as faCircleCheckRegular,
  faArrowRightArrowLeft,
  faArrowsToLine,
  faTimeline,
  faTimer,
  faBooks as faBooksRegular,
  faPhone,
  faEnvelope,
  faDoorOpen,
  faFileWaveform,
  faPrint,
  faCalculator,
  faIdCard,
  faRectangleHistoryCirclePlus,
  faRobot,
  faArrowLeft,
  faArrowUpRight,
  faDatabase
} from '@fortawesome/pro-regular-svg-icons'

import {
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faCalendarStar,
  faPlus,
  faMinus,
  faSortDown,
  faCheck as faCheckSolid,
  faTrophyAlt,
  faBooks,
  faShoppingBasket,
  faHeart,
  faUserLock as faUserLockSolid,
  faShoppingBag,
  faXmark,
  faBookOpen,
  faRunning,
  faPuzzlePiece,
  faTicket,
  faUserTag,
  faSchool,
  faBoxCheck,
  faHexagonPlus,
  faMagnifyingGlass as faMagnifyingGlassSolid,
  faMagnifyingGlassPlus as faMagnifyingGlassPlusSolid,
  faMagnifyingGlassMinus as faMagnifyingGlassMinusSolid,
  faUserTie,
  faTriangleExclamation,
  faCircleDollar as faCircleDollarSolid,
  faCreditCard,
  faRectangleBarcode,
  faUserGraduate as faUserGraduateSolid,
  faHandHoldingUsd,
  faClipboardCheck,
  faFilterList,
  faTruckRampBox,
  faPersonCarryBox,
  faCartCircleCheck,
  faCalendarCheck,
  faFileInvoiceDollar as faFileInvoiceDollarSolid,
  faTag,
  faLockKeyhole,
  faUser,
  faPersonToDoor,
  faBasketShoppingSimple,
  faCircleInfo,
  faInfo,
  faQrcode,
  faBan,
  faCalendarPen,
  faArrowRotateRight,
  faSchoolFlag,
  faFamily,
  faUserVneckHair,
  faUserTieHair,
  faBookMedical,
  faFolderOpen,
  faHandHoldingDollar,
  faFileSignature,
  faArrowsRotate,
  faArrowDownToLine,
  faImage,
  faHexagonExclamation,
  faCloudArrowUp,
  faCloudCheck,
  faCloudExclamation,
  faThumbsUp,
  faThumbsDown,
  faCloudArrowDown,
  faFaceSmilePlus,
  faAsterisk,
  faBadgePercent,
  faFilePdf,
  faGraduationCap as faGraduationCapSolid,
  faBadgeDollar,
  faMessageBot,
  faLock,
  faEye as faEyeSolid,
  faEyeSlash as faEyeSlashSolid,
  faGift,
  faBoxFull,
  faCalendarDays,
  faCalendarRange,
  faShirtRunning,
  faBoxesStacked,
  faWarehouseFull,
  faMoneyBillTransfer,
  faFileSpreadsheet,
  faRotate,
  faCalendarLinesPen,
  faShirt,
  faBoxDollar,
  faCartFlatbedBoxes,
  faBoxHeart,
  faSchoolCircleCheck,
  faTableList,
  faListTree,
  faPencil as faPencilSolid,
  faTrashCan as faTrashCanSolid,
  faBullseyePointer,
  faBolt,
  faScreenUsers,
  faCircleDollarToSlot,
  faMoneyCheckDollar,
  faCircleCheck,
  faCubes,
  faBook,
  faEllipsis,
  faFolderGrid,
  faListCheck,
  faCommentsQuestionCheck,
  faConveyorBelt,
  faReceipt,
  faUserPen as faUserPenSolid,
  faRectangleCode,
  faBullhorn,
  faFileChartPie,
  faClock,
  faRectangleHistory,
  faBell as faBellSolid,
  faConveyorBeltBoxes,
  faCalendarExclamation,
  faCalendar as faCalendarSolid,
  faCalendarXmark,
  faUserHair as faUserHairSolid,
  faFilter,
  faCopy,
  faPenToSquare as faPenToSquareSolid,
  faSquareDashedCirclePlus,
  faPenField
} from '@fortawesome/pro-solid-svg-icons'

import {
  faEnvelopeOpenDollar,
  faHeadset,
  faUserLock,
  faMoneyCheckDollarPen,
  faMagnifyingGlass,
  faFaceFrown,
  faFaceFrownSlight,
  faFaceGrinHearts,
  faFaceGrinStars,
  faFaceSmile,
  faFaceWorried,
  faFaceSmileRelaxed,
  faFaceLaugh,
  faFaceMehBlank,
  faFacePensive,
  faFaceMeh,
  faFaceSadTear,
  faFaceViewfinder,
  faMagnifyingGlassPlus as faMagnifyingGlassPlusLight,
  faShopSlash,
  faXmark as faXmarkLight,
  faXmarkLarge as faXmarkLargeLight,
  faFaceSmilePlus as faFaceSmilePlusLight,
  faPencil as faPencilLight,
  faTrashCan as faTrashCanLight,
  faSchoolFlag as faSchoolFlagLight,
  faChalkboardUser as faChalkboardUserLight,
  faScreenUsers as faScreenUsersLight,
  faCalendarStar as faCalendarStarLight,
  faBell,
  faGear as faGearLight,
  faUsers as faUsersLight,
  faBookOpenReader,
  faStore as faStoreLight,
  faCircleDollar as faCircleDollarLight,
  faGraduationCap as faGraduationCapLight,
  faBarsSort as faBarsSortLight,
  faFamily as faFamilyLight,
  faSquarePollVertical as faSquarePollVerticalLight,
  faWrench as faWrenchLight,
  faBookUser as faBookUserLight,
  faSchoolCircleCheck as faSchoolCircleCheckLight,
  faHouse,
  faLinkSimple,
  faLaptopCode,
  faArrowUpRightFromSquare as faArrowUpRightFromSquareLight,
  faTrashXmark,
  faCalendar,
  faCalendarRange as faCalendarRangeLight,
  faUserHair,
  faUserTie as faUserTieLight,
  faMoneyBillTransfer as faMoneyBillTransferLight,
  faMoneyFromBracket,
  faCartShopping,
  faArrowProgress,
  faXmarkToSlot,
  faCheckToSlot,
  faArrowRightLong as faArrowRightLongLight,
  faChartUser,
  faPaperPlane,
  faListUl,
  faSquareKanban,
  faCircleChevronDown,
  faChevronDown as faChevronDownLight,
  faChevronRight as faChevronRightLight,
  faChevronLeft as faChevronLeftLight,
  faChevronUp as faChevronUpLight,
  faCircleQuestion,
  faPenToSquare,
  faFiles,
  faPersonToDoor as faPersonToDoorLight,
  faArrowsTurnToDots,
  faLockKeyholeOpen,
  faCalendarCircleUser,
  faFileCheck,
  faFileCircleExclamation,
  faBarcodeRead as faBarcodeReadLight,
  faDroplet,
  faBroomWide,
  faUserRobot,
  faImages,
  faUserAstronaut
} from '@fortawesome/pro-light-svg-icons'

import {
  faXmarkLarge,
  faBoxFull as faBoxFullThin,
  faMessagePlus
} from '@fortawesome/pro-thin-svg-icons'

import {
  faCheck as faCheckSharpSolid
} from '@fortawesome/sharp-solid-svg-icons'

import {
  faGoogle
} from '@fortawesome/free-brands-svg-icons'

library.add( 
          faSignInAlt, faSignOutAlt, faRightLong, faEye, faEyeSlash, faGraduationCap, 
          faPencil, faTrashCan, faChevronDown, faChevronRight, faEnvelopeOpenDollar, faHeadset,
          faUserLock, faMoneyCheckDollarPen, faCog, faCircleDollar, faStore, faUserGraduate,
          faBookReader, faMagnifyingGlassPlus, faShuffle, faFileCertificate, faUserPen, faBarcodeRead,
          faMoneyBillWave, faFileInvoiceDollar, faBars, faUsers, faMagnifyingGlass, faHomeLgAlt, faCalendarStar, 
          faUserHeadset, faCheck, faMagnifyingGlassMinus, faSortDown, faCheckSolid ,faArrowRight, faTrophyAlt,
          faBooks, faShoppingBasket, faHeart, faUserLockSolid, faShoppingBag, faXmark, faPlus, faMinus,
          faLeftLong, faFaceFrown, faGoogle, faBookOpen, faRunning, faPuzzlePiece, faTicket,
          faMagnifyingGlassPlusLight, faUserTag, faSchool, faFaceMeh, faArrowUp, faShopSlash, faChevronLeft,
          faBoxCheck, faUpRightFromSquare, faMagnifyingGlassReg, faHexagonPlus, faUserTie, faTriangleExclamation,
          faCircleDollarSolid, faCreditCard, faRectangleBarcode, faUserGraduateSolid, faHandHoldingUsd,
          faClipboardCheck, faFilterList, faTruckRampBox, faPersonCarryBox, faCartCircleCheck, faCalendarCheck,
          faFileInvoiceDollarSolid, faTag, faLockKeyhole, faUser, faPersonToDoor, faBasketShoppingSimple,
          faFaceFrownSlight, faFaceGrinHearts, faFaceGrinStars, faFaceSmile, faFaceWorried, faFaceSmileRelaxed,
          faFaceLaugh, faFaceMehBlank, faFacePensive, faFaceSadTear, faCircleInfo, faInfo, faQrcode, faBan,
          faCalendarPen, faArrowRotateRight, faSchoolFlag, faFamily, faUserVneckHair, faUserTieHair, faBookMedical,
          faFolderOpen, faHandHoldingDollar, faFileSignature, faCalendarPen, faArrowRotateRight, faArrowsRotate,
          faArrowDownToLine, faImage, faHexagonExclamation, faCloudArrowUp, faCloudCheck, faCloudExclamation,
          faXmarkLight, faXmarkLarge, faXmarkLargeLight, faThumbsUp, faThumbsDown, faCloudArrowDown, 
          faSquarePollVertical, faBarsSort, faFaceSmilePlus, faFaceSmilePlusLight, faFaceSmilePlusRegular,
          faAsterisk, faMagnifyingGlassSolid, faBadgePercent, faFilePdf, faMagnifyingGlassPlusSolid, faMagnifyingGlassMinusSolid,
          faGraduationCapSolid, faBadgeDollar, faArrowUpRightFromSquare, faMessageBot, faSignature, faSignatureSlash,
          faFileSignatureRegular, faLock, faEyeSolid, faEyeSlashSolid, faGift, faBoxFull, faCalendarDays, 
          faCalendarRange, faShirtRunning, faBoxesStacked, faWarehouseFull, faMoneyBillTransfer, faRotate, 
          faFileSpreadsheet, faCalendarLinesPen, faShirt, faBoxDollar, faCartFlatbedBoxes, faBoxHeart,
          faSchoolCircleCheck, faTableList, faListTree, faBoxFullThin, faPencilLight, faTrashCanLight,
          faPencilSolid, faTrashCanSolid, faBullseyePointer, faBolt, faGroupArrowsRotate, faArrowsRotateRegular,
          faArrowLeftLong, faScreenUsers, faArrowRightLong, faCircleDollarToSlot, faMoneyCheckDollar,
          faCircleCheck, faSchoolFlagRegular, faChalkboardUser, faSchoolFlagLight, faChalkboardUserLight,
          faScreenUsersLight, faCalendarStarLight, faWrench, faCubes, faEllipsis, faBook, faFolderGrid,
          faListCheck, faCommentsQuestionCheck, faConveyorBelt, faReceipt, faChevronUp, faFaceViewfinder,
          faUserPenSolid, faRectangleCode, faArrowDownWideShort, faBadgePercentRegular, faCartXmark, faBell,
          faGearLight, faUsersLight, faBookOpenReader, faStoreLight, faCircleDollarLight, faGraduationCapLight,
          faBarsSortLight, faFamilyLight, faSquarePollVerticalLight, faWrenchLight, faBookUserLight,
          faSchoolCircleCheckLight, faHouse, faLinkSimple, faLaptopCode, faArrowUpRightFromSquareLight,
          faLinkSimpleRegular, faLaptopCodeRegular, faArrowUpRightFromSquareRegular, faTrashXmark,
          faUserTieHairRegular, faUserRegular, faBullhorn, faFileChartPie, faClock, faCheckSharpSolid,
          faEnvelopeCircleCheck, faRectangleHistory, faBellSolid, faCalendar, faCalendarRangeLight, faUserHair, 
          faMoneyBillTransferLight, faMoneyFromBracket, faCartShopping, faArrowProgress, faXmarkToSlot, faCheckToSlot,
          faArrowRightLongLight, faConveyorBeltBoxes, faChartUser, faPaperPlane, faListUl, faSquareKanban,
          faTrashXmarkRegular, faUserTieLight, faCircleChevronDown, faChevronDownLight, faChevronRightLight,
          faChevronLeftLight, faChevronUpLight, faXmarkRegular, faCircleCheckRegular, faCircleQuestion,
          faPenToSquare, faArrowRightArrowLeft, faFiles, faArrowsToLine, faTimeline, faTimer, faPersonToDoorLight,
          faCalendarExclamation, faBooksRegular, faClockRegular,faArrowsTurnToDots, faCalendarSolid, faCalendarXmark,
          faPhone, faEnvelope, faDoorOpen, faLockKeyholeOpen, faCalendarCircleUser, faFileCheck, faFileCircleExclamation, 
          faBarcodeReadLight, faFileWaveform, faDroplet, faUserHairSolid, faPrint, faFilter, faCopy, faCalculator, faIdCard,
          faPenToSquareSolid, faRectangleHistoryCirclePlus, faMessagePlus, faSquareDashedCirclePlus, faBroomWide, faUserRobot,
          faRobot, faArrowLeft, faPenField, faImages, faArrowUpRight, faDatabase, faUserAstronaut
        )

const store = createStore({
  modules: {
    auth: auth,
    checkout: checkout,
    matricula: matricula,
    desconto: desconto
  }
})

import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

const Emitter = require('tiny-emitter')
window.toaster = new Emitter()
window.spinner = new Emitter()
window.octa = new Emitter()
window.cart = new Emitter()
window.pagescroll = new Emitter()
window.scrollTo = new Emitter()
window.mattip = new Emitter()
window.matGet = new Emitter()

if (process.env.NODE_ENV === 'production') console.log = function() {}

//Notificações
import Echo from "laravel-echo"
const token = localStorage.getItem('token')

if ( process.env.VUE_APP_ENV == 'PROD') {
  
  window.io = require('socket.io-client')

  if (typeof io !== 'undefined') {
    window.Echo = new Echo({
      broadcaster: 'socket.io',
      host: process.env.VUE_APP_SOCKET_API,
        auth: {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    })
  }
}


// Axios interceptor
import axios from 'axios'

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      if (error.response.status === 401) {
        router.push('/login')
      } else {
        console.error('Ops. Algo deu errado.')
      }
    }
    return Promise.reject(error)
  }
)

import vue3GoogleLogin from 'vue3-google-login'
import VueGtag from "vue-gtag-next"

import Flicking from "@egjs/vue3-flicking"
import "@egjs/vue3-flicking/dist/flicking.css"



createApp(App)
  .use(router)
  .use(store)
  .use(createPinia())
  .use(PerfectScrollbar)
  .use(vue3GoogleLogin, {
    clientId: process.env.VUE_APP_GOOGLE_AUTH_CLIET_ID
  })
  .use(VueGtag, {
    property: {
      id: 'G-21QCWHTTBT'
    }
  })
  //includes
  .component('fa', FontAwesomeIcon)
  .component('flicking', Flicking)
  //template
  .component('controller', require('./components/template/Controller.vue').default)
  .component('app-controller', require('./components/template/AppController.vue').default)
  .component('external-controller', require('./components/template/ExternalController.vue').default)
  .component('card', require('./components/template/Card.vue').default)
  .component('page-header', require('./components/template/PageHeader.vue').default)
  //template loja
  .component('loja-controller', require('./components/loja/template/Controller.vue').default)
  .component('loja-page-header', require('./components/loja/template/PageHeader.vue').default)
  //form
  .component('input-control', require('./components/form/Input.vue').default)
  .component('select-control', require('./components/form/Select.vue').default)
  .component('textarea-control', require('./components/form/Textarea.vue').default)
  .component('checkbox-control', require('./components/form/Checkbox.vue').default)
  .component('switch-control', require('./components/form/Switch.vue').default)
  .component('autocomplete-select', require('./components/form/AutocompleteSelect.vue').default)
  .component('select-multiple', require('./components/form/SelectMultiple.vue').default)
  .component('file-control', require('./components/form/File.vue').default)
  //navigation
  .component('fast-menu', require('./components/navigation/FastMenu.vue').default)
  //components
  .component('list-filter', require('./components/filter/ListFilter.vue').default)
  .component('datatable', require('./components/datatable/DataTable.vue').default)
  //utilities
  .component('toaster', require('./components/utilities/Toaster.vue').default)
  .component('confirm', require('./components/utilities/Confirm.vue').default)
  .component('spinner', require('./components/utilities/Spinner.vue').default)
  .component('empty', require('./components/utilities/Empty.vue').default)
  //mount
  .mount('#app')
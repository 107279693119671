import moment from 'moment'
import axios from 'axios'
import authHeader from './auth-header'

export const scrollUp = () => {
  // const el = document.getElementsByClassName("main-scroller")[0]

  window.scrollTo({ top: 0, behavior: 'smooth' })
}

export const formatMoney = value => {
  value = parseFloat(value)
  let formatted

  if ( value === null || typeof value === "undefined" || typeof value !== "number" || Number.isNaN(value) ) {
    formatted = ""
  } else {
    formatted = value.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  
    formatted = "R$ " + formatted
  }

  return formatted
}

export const formatDate = value => {
  return moment(value,"YYYY-MM-DD").format("DD/MM/YYYY")
}

export const formatDateTime = (value, z, ns) => {
  if ( z ) {
    if ( ns ) {
      return moment.utc(value,"YYYY-MM-DDTHH:mm:ss").utcOffset('-0300').format("DD/MM/YYYY - HH:mm")
    } else {
      return moment.utc(value,"YYYY-MM-DDTHH:mm:ss").utcOffset('-0300').format("DD/MM/YYYY - HH:mm:ss")
    }
    
  } else {
    if ( ns ) {
      return moment(value,"YYYY-MM-DDTHH:mm:ss.Z").format("DD/MM/YYYY - HH:mm")
    } else {
      return moment(value,"YYYY-MM-DDTHH:mm:ss.Z").format("DD/MM/YYYY - HH:mm:ss")
    }
    
  }
}

export const convertUnix = (value, ds) => {
    return ds ? moment(value).format("YYYY-MM-DD HH:mm:ss") : moment(value).format("YYYY-MM-DD HH:mm")
}

export const unformatDate = value => {
  return moment(value,"DD/MM/YYYY").format("YYYY-MM-DD")
}

export const fromNow = value => {
  moment.locale('PT')
  return moment(value).fromNow()
}

export const divide = ( value, times ) => {
  let result = value / times
      result = parseFloat(result.toFixed(2))

  return result
}

export const multiply = ( value, times ) => {
  let result = value * times
      result = parseFloat(result.toFixed(2))

  return result
}

export const convertValue = value => {
  let formatted = value.replace(/\s/g, "").replace(",", ".")

  return formatted
}

export const translateFileSize = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const parseIcon = raw => {
  // console.log("raw:", raw)
  let icon

  if(raw !== null) {
    let iconArr = raw.split(" ")
    icon = [iconArr[0],iconArr[1]]
  } else {
    icon = ['far','arrow-right-long']
  }

  return icon
}

export const createSelectOptions = ( data, id, value ) => {
  if ( data ) {
    const options = []

    data.map (k => {
      const item = {
        value: id == 0 && value == 0 ? k : k[id],
        label: id == 0 && value == 0 ? k : k[value],
        ...k
      }
      options.push (item)
    })

    return options
  } else {
    return "Erro: Sem informações o suficiente para prosseguir"
  }
}

export const getCEP = cep => {
  console.log("getCEP:", cep)

  return axios.get('https://viacep.com.br/ws/' + cep + '/json/')
}

export const checkValidationDate = event => {
  console.log("event:", event)
}

export const getImagesFromBucket = ( idPessoa, src ) => {
  return axios.get(process.env.VUE_APP_API_URL + 'imagemdocumento/' + idPessoa + '/' + src,  { headers: authHeader() })
}

export const getImagesFromBucketPost = ( idPessoa, src ) => {
  const data = {
    file: src
  }
  return axios.post(process.env.VUE_APP_API_URL + 'imagemdocumento/new/' + idPessoa , data,  { headers: authHeader() })
}

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export const cleanCpf = ( cpf ) => {
  const drop = cpf.replaceAll('.', '').replace('-','')
  return drop
}

export const clearMask = ( src, data ) => {
  if ( src == 'phone' ) {
    const phone = data.replace(/ /g, '').replace('(','').replace(')','').replace('+55', '')
    return phone
  }
  if ( src == 'phonecc' ) {
    const phone = data.replace(/ /g, '').replace('(','').replace(')','')
    return phone
  }
  if ( src == 'cep' ) {
    const cep = data.replace('-', '')
    return cep
  }
  if ( src == 'cpf' ) {
    var cpf = data.replace('-', '').replaceAll('.','')
    if ( cpf.includes('/')) {
      cpf = cpf.replace('/', '')
    }
    return cpf
  }
  if ( src == 'rg' ) {
    const rg = data.replace('-', '').replaceAll('.','').replaceAll(' ','')
    return rg
  }
}

export const log = ( type, data ) => {
  return axios.post(process.env.VUE_APP_API_URL + `save/${ type }` , data,  { headers: authHeader() })
}
<template>
  <section>
    
  </section>
</template>

<script>
export default {
  data () {
    return {
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null
    }
  },
  mounted () {
    window.location.href = 'https://renegociacao.app.rededecisao.com/?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjY4ODAsImlzcyI6Imh0dHA6Ly9sb2NhbGhvc3Q6ODAwMC9hcGkvYXV0aC9sb2dpbiIsImlhdCI6MTU2MzQ3NzY4NywiZXhwIjoxNTYzNDgxMjg3LCJuYmYiOjE1NjM0Nzc2ODcsImp0aSI6ImRtRjZsYUMwWUswVTdzSVIifQ.ynkU2CQ2Kr3yt-cxA3o21y2wkg2HRj-zckdBYDy2MNE&email=' + this.currentUser.email
  }
} 
</script>

<style>

</style>
<template>
  <section class="appController">
    <spinner />
    <toaster />
    <header-controller :env="env"></header-controller>
    <sidebar-controller @onSuperMenu="toggleSuperMenu" :active="activeMenu" @setActive="setActiveMenu" :env="env"></sidebar-controller>
    <transition name="fadein">
      <super-menu :scope="supermenu" v-if="supermenu" @close="closeSuperMenu" ></super-menu>
    </transition>
    <main>
      <div :class="['main-content', { lock: lock }]" ref="mainContent" @scroll="scroll">
        <slot></slot>
      </div>
    </main>
    <div v-if="env != 'PROD'" class="notProdAlert">
      <b>Atenção!</b> Você está no ambiente de <b>{{ env == 'HOM' ? 'homologação' : 'desenvolvimento' }}</b>. As ações executadas neste ambiente não refletem no ambiente de produção.
    </div>
    <transition name="slideup">
      <a 
        v-if="goToTopButton && goToTopButtonActive" 
        href="#" 
        @click="goToTop()"
        class="btn btn-primary btn-icon btn-round goToTopButton"
        >
        <fa :icon="['far', 'arrow-up']" class="icon"></fa>
      </a>
    </transition>
  </section>
</template>

<script>
import HeaderController from '@/components/template/Header.vue'
import SidebarController from '@/components/navigation/Sidebar.vue'
import SuperMenu from '@/components/navigation/SuperMenu.vue'

export default {
  name: 'Template',
  components: {
    HeaderController, SidebarController, SuperMenu
  },
  props: {
    lockScroll: {
      type: Boolean,
      default: false
    },
    goToTopButton: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    lockScroll ( value ) {
      this.lockBody(value)
    }
  },
  data () {
    return {
      env: null,
      octaScript: null,
      options: {
        suppressScrollY: false,
        minScrollbarLength: 80,
      },
      goToTopButtonActive: false,
      ease: null,
      scrollTop: 0,
      speed: 100,
      rate: 1,
      supermenu: null,
      activeMenu: null,
      lock: false
    }
  },
  mounted () {
    this.checkDevMode()
    // this.addOcta()

    window.pagescroll.on('lock', () => {
      console.log("lock scroll")
      window.scroll({
        top: 0,
        behavior: 'instant'
      })
      this.lockBody(true)
    })

    window.pagescroll.on('unlock', () => {
      // console.log("unlock store scroll")
      this.lockBody(false)
    })

    this.lockBody(this.lockScroll)

    // this.$refs.mainContent.addEventListener('scroll', this.scroll)
  },
  methods: {
    checkDevMode () {
      this.env = process.env.VUE_APP_ENV
    },
    addOcta () {
      // // console.log("add octa now...")
      // this.octaScript = document.createElement('script')
      // this.octaScript.setAttribute('src', '/octa.js')
      // // console.log("externalScript:", externalScript)
      
      // document.head.appendChild(this.octaScript)

      // const octa = document.getElementById('octadesk-octachat-appchat')
      // if ( octa ) octa.removeAttribute('class', 'hidden')
    },
    removeOcta () {
      // console.log("remove octa...")
      const octa = document.getElementById('octadesk-octachat-appchat')
      octa.setAttribute('class', 'hidden')
      // document.head.removeChild(this.octaScript)
      // document.getElementById("octaScript").parentNode().removeChild(this.octaScript)
      // document.head.removeChild(this.octaScript)
    },
    toggleSuperMenu ( supermenu ) {
      this.supermenu = null
      console.log("supermenu",supermenu)
      
      this.$nextTick(() => {
        this.supermenu = supermenu
        if ( supermenu ) this.lockBody(true)
      })
    },
    closeSuperMenu () {
      this.supermenu = null
      this.activeMenu = null
      this.lockBody(false)
    },
    setActiveMenu ( active ) {
      // console.log("setActiveMenu:", active)
      if ( active ) {
        this.activeMenu = active
      } else {
        this.closeSuperMenu()
      }
      
    },
    lockBody ( state ) {
      this.lock = state
      // if ( state ) {
      //   document.body.style.overflowY = 'hidden'
      // } else {
      //   document.body.style.overflowY = 'auto'
      // }
    },
    scroll ( e ) {
      // console.log("e:", e)
      this.goToTopButtonActive = e.target.scrollTop > 100
    },
    goToTop () {
      this.$refs.mainContent.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}

</script>

<style lang="scss" scoped>
  .appController {
    display: flex; align-items:stretch;
    flex-wrap: wrap; height: 100vh; width: 100vw;
    overflow: hidden;
  }
  main {
    // padding: 60px 0 0 60px;
    flex: 1;  max-width: calc(100% - 60px);
    position: relative;
    background-color: $color-secondary;
    // min-height: 100vh;

    .main-content {
      width: 100%; height: auto;
      position: sticky; top: 60px;
      background-color: $color-bg;
      height: calc(100vh - 60px);
      border-top-left-radius: 6px;
      border-left: $border-component; border-top: $border-component;
      overflow-y: auto;
      @extend %scrollbar_light;

      &.lock {
        overflow-y: hidden;
      }
    }

    // &:before {
    //   content: "";
    //   display: block; width: calc(100vw - 60px); height: calc(100vh - 60px);
    //   border-top-left-radius: 10px;
    //   border-left: $border-component; border-top: $border-component;
    //   position: fixed; bottom: 0; right: 0;
    //   pointer-events: none;
    //   z-index: 200;
    // }
  }

  .notProdAlert {
    position: fixed;
    width: 100%; max-width: 740px;
    bottom: 0; left: 0; right: 0; margin: auto;
    background-color: $color-warning;
    color: $color-dark;
    padding: 3px $mg_mini;
    border-top-left-radius: $border-radius-small;
    border-top-right-radius: $border-radius-small;
    z-index: 1000;
    font-size: 11px;
    text-align: center;
    box-shadow: 2px 1px 12px rgba(145, 107, 21, 0.4) !important;
    pointer-events: none;
    opacity: .8;
  }

  .goToTopButton {
    position: fixed; bottom: $mg; right: $hmg_mid;
    z-index: 5000;
    @extend %transition_4e;
  }
</style>
<template>
  <section class="gatilhoForm over-page" ref="gatilhoForm">
    <main class="frame">
      <card :listed="false" :boxed="false">
        <div class="form-wrap">
          <header>
            <h3>{{ gatilho.idGatilhoIntervencao ? 'Editar' : 'Criar' }} Gatilho</h3>
            <p v-if="gatilho.nome">{{ gatilho.nome }}</p>
          </header>
          <div>
            <div class="inner-content">
              <div class="row nop nom">
                <div class="col-md-6 form-group no-padd-left">
                  <label>Nome</label>
                  <input-control 
                    v-model="gatilho.nome" 
                    placeholder="Ex. Gatilho Intervenção" 
                    name="nome"
                    type="text" 
                    :valid="valid.nome" 
                    :disabled="loadingInputContent" 
                    @keyup="valid.nome = true"
                    required
                  />
                </div>
                <!--<div class="col-md-3 form-group no-padd-left">
                  <label>Público</label>
                  <select-control 
                    v-model="gatilho.publico"
                    :options="options.publicos"
                    placeholder="Selecione" 
                    name="publico" 
                    :valid="valid.publico" 
                    :disabled="loadingInputContent" 
                    @keyup="valid.publico = true"
                    required
                  />
                </div>-->
                <div class="col-md-3 form-group no-padd-left">
                  <label>Categoria</label>
                  <select-control 
                    v-model="gatilho.categoriaGatilho"
                    :options="options.categorias"
                    placeholder="Selecione" 
                    name="categoriaGatilho" 
                    :valid="valid.categoriaGatilho" 
                    :disabled="loadingInputContent" 
                    @keyup="valid.categoriaGatilho = true"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="inner-content">
              <div class="row nop nom">
                <div class="col-md-3 form-group no-padd-left">
                  <label>Utilizar Apenas Query</label>
                  <div class="switchWrap">
                    <switch-control v-model:checked="gatilho.queryRaw" labelOn="Sim" labelOff="Não" :invert="true"></switch-control>
                  </div>
                </div>
                <div class="col-md-3 form-group no-padd-left" v-if="!gatilho.queryRaw">
                  <label>Agregar Análise Financeira</label>
                  <div class="switchWrap">
                    <switch-control v-model:checked="gatilho.analiseFinanceira" labelOn="Agregar" labelOff="Não agregar" :invert="true"></switch-control>
                  </div>
                </div>
              </div>
              <div class="row nop nom" v-if="!gatilho.queryRaw">
                <div class="col-md-5 form-group no-padd-left">
                  <label>Campo Análise Integrada</label>
                  <select-control 
                    v-model="gatilho.analise_integrada"
                    :options="options.analiseIntegrada"
                    placeholder="Selecione"
                    name="analise_integrada" 
                    :valid="valid.analise_integrada" 
                    :disabled="loadingInputContent" 
                    @keyup="valid.analise_integrada = true"
                    required
                  />
                </div>
                <div class="col-md-2 form-group no-padd-left">
                  <label>Regra</label>
                  <select-control 
                    v-model="gatilho.regra"
                    :options="options.regras"
                    placeholder="Selecione"
                    name="regra" 
                    :valid="valid.regra" 
                    :disabled="loadingInputContent" 
                    @keyup="valid.regra = true"
                    required
                  />
                </div>
                <div class="col-md-2 form-group no-padd-left">
                  <label>Valor</label>
                  <input-control 
                    v-model="gatilho.metrica" 
                    placeholder="Número, Texto, etc.." 
                    name="metrica"
                    type="text" 
                    :valid="valid.metrica" 
                    :disabled="loadingInputContent" 
                    @keyup="valid.metrica = true"
                    required
                  />
                </div>
              </div>
              <div class="row nop nom" >
                <!-- <div class="col-md-3 form-group no-padd-left" v-if="gatilho.queryRaw">
                  <label>Plataforma</label>
                  <select-control 
                    v-model="gatilho.plataformaDisparo"
                    :options="options.plataformas"
                    placeholder="Selecione" 
                    name="plataformaDisparo" 
                    :valid="valid.plataformaDisparo" 
                    :disabled="loadingInputContent" 
                    @keyup="valid.plataformaDisparo = true"
                    required
                  />
                </div> -->
                <div class="col-md-4 form-group no-padd-left" v-if="gatilho.queryRaw">
                  <label>Base</label>
                  <select-control
                    v-model="gatilho.database"
                    :options="options.databases"
                    placeholder="Selecione"
                    name="database"
                    :disabled="loadingInputContent"
                    required
                  />
                </div>
                <div class="col-md-12 form-group no-padd-left">
                  <label>Expressão (SQL)</label>
                  <!-- <textarea-control
                    v-model="gatilho.expressao"
                    placeholder="Ex. periodo_letivo = '2023' and unidade = 'DEC'" 
                    name="expressao"
                    size="large"
                    :valid="valid.expressao" 
                    :disabled="loadingInputContent" 
                    @keyup="valid.expressao = true"
                    @change="validateSql"
                    resize
                  /> -->
                  <div class="code">
                    <codemirror
                      v-model="gatilho.expressao"
                      placeholder="Ex. SELECT * FROM matricula WHERE periodo_letivo = '2023' and unidade = 'DEC'"
                      :style="{ height: '400px' }"
                      :autofocus="true"
                      :indent-with-tab="true"
                      :extensions="[sql()]"  
                      :tab-size="2"
                      :options="cmOptions"
                      @change="updateExpressao"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="inner-content">
              <div class="row no-padd">
                <div class="col-md-4 form-group no-padd-left">
                  <label>Plataforma</label>
                  <select-control 
                    v-model="gatilho.plataformaDisparo"
                    :options="options.plataformas"
                    placeholder="Selecione" 
                    name="plataformaDisparo" 
                    :valid="valid.plataformaDisparo" 
                    :disabled="loadingInputContent" 
                    @keyup="valid.plataformaDisparo = true"
                    required
                  />
                </div>
              </div>
            </div>
            <div v-if="gatilho.plataformaDisparo" class="inner-content">
              <h5 class="color-text">Configurações disparo {{ getNomePlataforma() }}</h5>

              <!-- APP -->
              <transition name="slideup-small">
                <div class="row no-padd" v-if="gatilho.plataformaDisparo == 'App'">
                  <div class="col-md-3 form-group no-padd-left">
                    <label>Tipo de Mensagem</label>
                    <select-control 
                      v-model="gatilho.tipoMensagemApp"
                      :options="options.tiposMensagemApp"
                      placeholder="Selecione"
                      name="tipoMensagemApp"
                      :valid="valid.tipoMensagemApp"
                      :disabled="loadingInputContent"
                      @keyup="valid.tipoMensagemApp = true"
                      required
                    />
                  </div>
                  <div class="col-md-4 form-group no-padd-left">
                    <label>Título</label>
                    <input-control 
                      v-model="gatilho.sumarioApp"
                      placeholder="Ex: Início das Aulas"
                      name="sumarioApp"
                      :valid="valid.sumarioApp"
                      :disabled="loadingInputContent"
                      @keyup="valid.sumarioApp = true"
                      required
                    />
                  </div>
                  <div class="col-md-12 form-group no-padd-left">
                    <upload-imagens-controller></upload-imagens-controller>
                  </div>
                  <div class="col-md-12 form-group no-padd-left">
                    <label>Mensagem</label>
                    <textarea-control 
                      v-model="gatilho.mensagemAPP"
                      placeholder="Escreva sua mensagem"
                      name="mensagemAPP"
                      size="large"
                      :valid="valid.mensagemAPP"
                      :disabled="loadingInputContent"
                      @keyup="valid.mensagemAPP = true"
                      resize
                      required
                    />
                  </div>
                </div>
              </transition>
              <!-- APP -->

              <!-- EMAIL -->
              <transition name="slideup-small">
                <div class="row no-padd" v-if="gatilho.plataformaDisparo == 'Email'">
                  <div class="col-md-4 form-group no-padd-left">
                    <label>Assunto</label>
                    <input-control 
                      v-model="gatilho.assuntoEmail"
                      placeholder="Ex: Início das Aulas"
                      name="assuntoEmail"
                      :valid="valid.assuntoEmail"
                      :disabled="loadingInputContent"
                      @keyup="valid.assuntoEmail = true"
                      required
                    />
                  </div>
                  <div class="col-md-12 form-group no-padd-left">
                    <label>Mensagem</label>
                    <textarea-control 
                      v-model="gatilho.textoEmail"
                      placeholder="Escreva sua mensagem"
                      name="textoEmail"
                      :valid="valid.textoEmail"
                      :disabled="loadingInputContent"
                      @keyup="valid.textoEmail = true"
                      size="xlarge"
                      resize
                      required
                    />
                  </div>
                </div>
              </transition>
              <!-- EMAIL -->

              <!-- Blip -->
              <transition name="slideup-small">
                <div class="row no-padd" v-if="gatilho.plataformaDisparo == 'Blip'">
                  <div class="col-md-12 form-group no-padd-left">
                    <label>Template</label>
                    <div class="templateSelect">
                      <ul>
                        <li v-for="template in options.wpTemplates" :key="template">
                          <label>
                            <span class="tsCheck">
                              <input type="radio" v-model="gatilho.template" :value="template.id" @change="createTemplateVarsInputs()" name="template"/>
                            </span>
                            <card boxed :listed="false" hover>
                              <p v-html="template.content"></p>
                            </card>
                          </label>
                        </li>
                      </ul>
                    </div>
                    
                    <!-- <input-control 
                      v-model="gatilho.template"
                      placeholder="Ex: pos_atendimento_pipedrive"
                      name="template"
                      :valid="valid.template"
                      :disabled="loadingInputContent"
                      @keyup="valid.template = true"
                      required
                    /> -->
                  </div>
                  <div class="col-md-12 form-group no-padd-left" v-if="gatilho.templateVars.length > 0">
                    <label>Variáveis</label>
                    <ul class="templateVars">
                      <li v-for="v in gatilho.templateVars" :key="v">
                        <input-control v-model="v.value" :placeholder="v.key"/>
                      </li>
                    </ul>
                  </div>
                </div>
              </transition>
              <!-- Blip -->

              <!-- SMS -->
              <!-- <transition name="slideup-small">
                <div class="row no-padd" v-if="gatilho.plataformaDisparo == 'SMS'">
                  <div class="col-md-12 form-group no-padd-left">
                    <label>Mensagem</label>
                    <textarea-control
                      v-model="gatilho.textoSMS"
                      placeholder="Escreva sua mensagem"
                      name="textoSMS"
                      size="large"
                      :valid="valid.textoSMS"
                      :disabled="loadingInputContent"
                      @keyup="valid.textoSMS = true"
                      maxlength="160"
                      required
                    />
                  </div>
                </div>
              </transition> -->
              <!-- SMS -->

              <!-- ATENDIMENTO -->
              <transition name="slideup-small">
                <div class="row no-padd" v-if="gatilho.plataformaDisparo == 'Atendimento'">
                  <div class="col-md-4 form-group no-padd-left">
                    <label>Categoria de Atividades</label>
                    <select-control
                      v-model="gatilho.idAtividadeCategoria"
                      :options="options.categoriasAtendimento"
                      placeholder="Selecione"
                      name="categoriaAtendimento"
                      :valid="valid.idAtividadeCategoria"
                      :disabled="loadingInputContent"
                      @keyup="valid.idAtividadeCategoria = true"
                      @change="gatilho.categoriaAtendimento = gatilho.idAtividadeCategoria"
                      required
                    />
                  </div>
                  <div class="col-md-3 form-group no-padd-left">
                    <label>Canal de Atendimento</label>
                    <select-control
                      v-model="gatilho.canalAtendimento"
                      :options="options.canaisAtendimento"
                      placeholder="Selecione"
                      name="canalAtendimento"
                      :valid="valid.canalAtendimento"
                      :disabled="loadingInputContent"
                      @keyup="valid.canalAtendimento = true"
                      required
                    />
                  </div>
                </div>
              </transition>
              <!-- ATENDIMENTO -->

              <!-- AGENDA EDU - NOTIFICAÇÕES -->
              <transition-group name="slideup-small">
                <div class="row no-padd" v-if="gatilho.plataformaDisparo == 'AgendaEduNotificacao'">
                  <div class="col-md-3 form-group no-padd-left">
                    <label>Copiar o aluno?</label>
                    <div class="switchWrap">
                      <switch-control v-model:checked="gatilho.ae_notificacao.student_can_see" labelOn="Sim" labelOff="Não" :invert="true"></switch-control>
                    </div>
                  </div>
                  <div class="col-md-4 form-group no-padd-left">
                    <label>Categoria</label>
                    <select-control
                      v-model="gatilho.ae_notificacao.category"
                      :options="options.categoriasNotificacoes"
                      placeholder="Selecione"
                      name="categoriasNotificacoes"
                      :valid="valid.categoriaNotificacao"
                      :disabled="loadingInputContent"
                      @keyup="valid.categoriaNotificacao = true"
                      required
                    />
                  </div>
                </div>
                <div class="row no-padd" v-if="gatilho.plataformaDisparo == 'AgendaEduNotificacao'">
                  <div class="col-md-12 form-group no-padd-left">
                    <label>Título</label>
                    <input-control 
                      v-model="gatilho.ae_notificacao.title"
                      placeholder="Ex: Início das Aulas"
                      name="sumarioApp"
                      :valid="valid.tituloNotificacao"
                      :disabled="loadingInputContent"
                      @keyup="valid.tituloNotificacao = true"
                      required
                    />
                  </div>
                  <div class="col-md-12 form-group no-padd-left">
                    <label>Mensagem</label>
                    <textarea-control 
                      v-model="gatilho.ae_notificacao.description"
                      placeholder="Ex: Início das Aulas"
                      name="sumarioApp"
                      :valid="valid.mensagemNotificacao"
                      :disabled="loadingInputContent"
                      @keyup="valid.mensagemNotificacao = true"
                      required
                    />
                  </div>
                </div>
              </transition-group>
              <!-- AGENDA EDU - NOTIFICAÇÕES -->

              <!-- AGENDA EDU - COMUNICADO -->
              <transition-group name="slideup-small">
                <div class="row no-padd" v-if="gatilho.plataformaDisparo == 'AgendaEduComunicado'">
                  <div class="col-md-3 form-group no-padd-left">
                    <label>Para quem vai enviar</label>
                    <select-control
                      v-model="gatilho.ae_comunicado.sendTo"
                      :options="options.paraQuemComunicado"
                      placeholder="Selecione"
                      name="categoriasNotificacoes"
                      :valid="valid.paraQuemComunicado"
                      :disabled="loadingInputContent"
                      @keyup="valid.paraQuemComunicado = true"
                      required
                    />
                  </div>
                </div>
                <div class="row no-padd" v-if="gatilho.plataformaDisparo == 'AgendaEduComunicado'">
                  <div class="col-md-12 form-group no-padd-left">
                    <label>Título</label>
                    <input-control 
                      v-model="gatilho.ae_comunicado.title"
                      placeholder="Ex: Início das Aulas"
                      name="sumarioApp"
                      :valid="valid.tituloComunicado"
                      :disabled="loadingInputContent"
                      @keyup="valid.tituloComunicado = true"
                      required
                    />
                  </div>
                  <div class="col-md-12 form-group no-padd-left">
                    <label>Mensagem</label>
                    <textarea-control 
                      v-model="gatilho.ae_comunicado.description"
                      placeholder="Ex: Início das Aulas"
                      name="sumarioApp"
                      :valid="valid.mensagemComunicado"
                      :disabled="loadingInputContent"
                      @keyup="valid.mensagemComunicado = true"
                      required
                    />
                  </div>
                  <div class="col-md-4 form-group no-padd-left">
                    <label>Criado para</label>
                    <select-control
                      v-model="gatilho.ae_comunicado.createdFor"
                      :options="options.comunicadoCriadoPara"
                      placeholder="Selecione"
                      name="comunicadoCriadoPara"
                      :valid="valid.comunicadoCriadoPara"
                      :disabled="loadingInputContent"
                      @keyup="valid.comunicadoCriadoPara = true"
                      required
                    />
                  </div>
                  <div class="col-md-4 form-group no-padd-left">
                    <label>Categoria</label>
                    <select-control
                      v-model="gatilho.ae_comunicado.categoryId"
                      :options="options.categoriasComunicados"
                      placeholder="Selecione"
                      name="categoriasComunicados"
                      :valid="valid.categoriasComunicados"
                      :disabled="loadingInputContent"
                      @keyup="valid.categoriasComunicados = true"
                      required
                    />
                  </div>
                </div>
                <div class="row no-padd" v-if="gatilho.plataformaDisparo == 'AgendaEduComunicado'">
                  <label>Capa</label>
                  <file-control placeholder="Adicionar anexo" @change="addCapaComunicado" :thumb="anexo" />
                </div>
              </transition-group>
              <!-- AGENDA EDU - COMUNICADO -->

            </div>
            <div class="inner-content">
              <h5 class="color-text">Frequência de disparo</h5>
              <div class="row no-padd">
                <div class="col-md-2 form-group no-padd-left">
                  <label>Recorrente</label>
                  <div class="switchWrap">
                    <switch-control v-model:checked="gatilho.recorrente" labelOn="Recorrente" labelOff="Único" :invert="true"></switch-control>
                  </div>
                </div>
                <div class="col-md-2 form-group no-padd-left" v-if="gatilho.recorrente">
                  <label>Recorrência</label>
                  <select-control 
                    v-model="gatilho.recorrencia"
                    :options="options.recorrencias"
                    placeholder="Selecione"
                    name="recorrencia"
                    :valid="valid.recorrencia"
                    :disabled="loadingInputContent"
                    @keyup="valid.recorrencia = true"
                    required
                  />
                </div>
                <template v-if="gatilho.recorrente">
                  <div class="col-md-2 form-group no-padd-left" >
                    <label>Hora do Disaro</label>
                    <input-control 
                      v-model="gatilho.hora"
                      placeholder="00:00"
                      name="hora"
                      type="time"
                      :valid="valid.hora"
                      :disabled="loadingInputContent"
                      @keyup="valid.hora = true"
                      required
                    />
                  </div>
                  <div class="col-md-2 form-group no-padd-left" v-if="gatilho.recorrencia == 'semanal'">
                    <label>Dia da Semana</label>
                    <select-control
                      v-model="gatilho.diaSemana"
                      :options="options.diaSemana"
                      placeholder="Selecione"
                      name="diaSemana"
                      :valid="valid.diaSemana"
                      :disabled="loadingInputContent"
                      @keyup="valid.diaSemana = true"
                      required
                    />
                  </div>
                  <div class="col-md-2 form-group no-padd-left" v-if="gatilho.recorrencia == 'mensal'">
                    <label>Dia do Mês</label>
                    <input-control 
                      v-model="gatilho.diaMes"
                      placeholder="20"
                      name="diaMes"
                      type="number"
                      :valid="valid.diaMes"
                      :disabled="loadingInputContent"
                      @change="limitDay()"
                      @keyup="valid.diaMes = true"
                      required
                    />
                  </div>
                </template>
              </div>
            </div>
            <div class="inner-content">
              <h5 class="color-text">Testes</h5>
              <div class="row no-padd">
                <div class="col-md-2 form-group no-padd-left">
                  <label>É um teste?</label>
                  <div class="switchWrap">
                    <switch-control v-model:checked="gatilho.teste" labelOn="Sim" labelOff="Não" :invert="true"></switch-control>
                  </div>
                </div>
                <template v-if="gatilho.teste">
                  <div class="col-md-3 form-group no-padd-left" v-if="gatilho.plataformaDisparo == 'Email'">
                    <label>Nome</label>
                    <input-control 
                      v-model="gatilho.testeNome" 
                      placeholder="Nome do Responsável" 
                      name="testeNome"
                      type="text"
                      :valid="valid.testeNome" 
                      :disabled="loadingInputContent" 
                      @keyup="valid.testeNome = true"
                      required
                    />
                  </div>
                  <!-- <div class="col-md-3 form-group no-padd-left" v-if="gatilho.publico == 'aluno'">
                    <label>Nome Aluno</label>
                    <input-control 
                      v-model="gatilho.testeNomeAluno" 
                      placeholder="Nome do Aluno" 
                      name="testeNomeAluno"
                      type="text"
                      :valid="valid.testeNomeAluno" 
                      :disabled="loadingInputContent" 
                      @keyup="valid.testeNomeAluno = true"
                      required
                    />
                  </div> -->
                  <div class="col-md-2 form-group no-padd-left" v-if="gatilho.plataformaDisparo == 'Blip' || gatilho.plataformaDisparo == 'SMS'">
                    <label>Telefone</label>
                    <input-control 
                      v-model="gatilho.testeTelefone" 
                      placeholder="+5511987654321" 
                      name="testeTelefone"
                      type="text"
                      :valid="valid.testeTelefone" 
                      :disabled="loadingInputContent" 
                      @keyup="valid.testeTelefone = true"
                      @keydown="addCountryCode('testeTelefone', $event)"
                      required
                      masking="telefone"
                    />
                  </div>
                  <div class="col-md-3 form-group no-padd-left" v-if="gatilho.plataformaDisparo == 'App' || gatilho.plataformaDisparo == 'Email'">
                    <label>Email Responsável</label>
                    <input-control 
                      v-model="gatilho.testeEmail" 
                      placeholder="pessoa@email.com.br" 
                      name="testeEmail"
                      type="text"
                      :valid="valid.testeEmail" 
                      :disabled="loadingInputContent" 
                      @keyup="valid.testeEmail = true"
                      required
                    />
                  </div>
                  <!-- <div class="col-md-3 form-group no-padd-left" v-if="(gatilho.plataformaDisparo == 'App' || gatilho.plataformaDisparo == 'Email') && gatilho.publico == 'aluno' ">
                    <label>Email Aluno</label>
                    <input-control 
                      v-model="gatilho.testeEmailAluno" 
                      placeholder="pessoa@email.com.br" 
                      name="testeEmailAluno"
                      type="text"
                      :valid="valid.testeEmailAluno" 
                      :disabled="loadingInputContent" 
                      @keyup="valid.testeEmailAluno = true"
                      required
                    /> 
                  </div> -->
                  <div class="col-md-3 form-group no-padd-left" v-if="gatilho.publico == 'aluno' && gatilho.plataformaDisparo == 'Atendimento'">
                  <label>Unidade do Aluno</label>
                  <select-control 
                    v-model="gatilho.testeUnidadeAluno"
                    :options="options.unidades"
                    placeholder="Selecione"
                    name="testeUnidadeAluno"
                    :valid="valid.testeUnidadeAluno"
                    :disabled="loadingInputContent"
                    @keyup="valid.testeUnidadeAluno = true"
                    required
                  />
                </div>
                </template>
                
              </div>
            </div>
            <div class="inner-content">
              <h5 class="color-text">Status</h5>
              <div class="col-md-2 form-group no-padd-left">
                <!-- <label>Ativo</label> -->
                <div class="switchWrap">
                  <switch-control v-model:checked="gatilho.ativo" labelOn="Ativo" labelOff="Inativo" :invert="true"></switch-control>
                </div>
              </div>
            </div>
            <div class="row no-padd">
              <div class="col-md-12 form-actions form-group">
                <a href="#" class="btn btn-primary" @click.prevent="save()">
                  Salvar
                </a>
                <a href="#" class="btn btn-secondary" @click.prevent="cancel()">
                  Cancelar
                </a>
              </div>
            </div>
          </div>
        </div>
      </card>
    </main>
  </section>
</template>

<script>
import FerramentasService from '@/services/ferramentas.service'
import AtendimentoService from '@/services/atendimento.service'
import FormService from '@/services/form.service'
import UserService from '@/services/user.service'
import AgendaEduService from '@/services/agendaedu.service.js'

import { createSelectOptions, clearMask } from '@/services/utils.service'
import UploadImagensController from  '@/components/ferramentas/uploadImagens/template/UploadImagensController'

import { Codemirror } from 'vue-codemirror'
import { sql } from '@codemirror/lang-sql'


export default {
  components: {
    UploadImagensController,
    Codemirror
  },
  data () {
    return {
      gatilho: {
        nome: null, //Descrição do Gatilho/Intervenção
        publico: null, //Público alvo
        categoriaGatilho: null, //Categoria de iniciativas: Ativação Alunos, Ativação Responsáveis, Pedagógico, Contrato
        etapa: null, //Ordem em que o gatilho será executado
        analise_integrada: null, //Coluna da análise integrada que será utilizada na consulta
        expressao: null, //SQL adicional para a consulta
        plataformaDisparo: null, //Plataforma onde será disparado o gatilho selecionado: App, E-mail, Whatsapp, SMS, Atendimento
        template: null, //Identificador de template para Whatsapp (Hash)
        assuntoEmail: null, //Assunto do email enviado
        textoEmail: null, //Texto (html) para envio por e-mail.
        textoSMS: null, //Texto Simples para envio por SMS.
        regra: null, //Operador: =, !=, >, >=, <, <=, Like, Not Like
        detalhamento: null, //Descrição detalhada do disparo
        metrica: null, //Valor de comparação para seleção na análise integrada 
        mensagemAPP: null, //Texto de mensagem a ser disparado no APP
        tipoMensagemApp: null, //Tipo de mensagem no APP: COMUNICADO, NOTICIA, PORTIFOLIO, OCORRENCIA, CHEGADA, SAIDA
        sumarioApp: null, //Título da mensagem  para APP
        categoriaAtendimento: null, //Id da categoria de atendimento no Apollo
        idAtividadeCategoria: null, //Id da categoria de atendimento no Apollo
        canalAtendimento: null, //ID do canal de atendimento no Apollo
        recorrente: false, //Indicativo se o disparo é recorrente ou apenas único
        recorrencia: null, //Frequencia da recorrência do disparo: Diário, Semanal, Anual
        teste: false, //O gatilho está em teste ou não
        testeNome: null, //Nome de quem receberá o teste
        testeEmail: null, //Email para qual deve ser enviado o teste
        testeTelefone: null, //Telefone para onde deve ser enviado o teste
        testeNomeAluno: null, //Nome do aluno que aparecerá no teste
        testeEmailAluno: null, //Email do aluno para qual deve ser enviado o teste
        testeUnidadeAluno: null, //Unidade do aluno que aparecerá no teste
        ativo: null, //O gatilho está ativo ou não
        hora: null, //hora do disparo
        diaMes: null, //dia do mês do disparo (recorrente) 1-31
        diaSemana: null, //dia da semana do disparo (recorrente) 0-6
        queryRaw: false, //utilizar apenas mysql
        database: 'dataWarehouse',
        templateVars: [],
        ae_notificacao: {
          title: null,
          description: null,
          student_can_see: false,
          from: false,
          category: null
        },
        ae_comunicado: {
          sendTo: null,
          title: null,
          description: null,
          createdFor: null,
          categoryId: null
        }
      },
      bkp: null,
      valid: {
        nome: true,
        publico: true,
        categoriaGatilho: true,
        etapa: true,
        analise_integrada: true,
        plataformaDisparo: true,
        template: true,
        assuntoEmail: true,
        textoEmail: true,
        textoSMS: true,
        regra: true,
        detalhamento: true,
        metrica: true,
        mensagemAPP: true,
        tipoMensagemApp: true,
        sumarioApp: true,
        categoriaAtendimento: true,
        canalAtendimento: true,
        recorrente: true,
        recorrencia: true,
        teste: true,
        testeNome: true,
        testeEmail: true,
        testeTelefone: true,
        testeNomeAluno: true,
        testeUnidadeAluno: true,
        ativo: true,
        expressao: true,
        hora: true,
        categoriaNotificacao: true,
        tituloNotificacao: true,
        mensagemNotificacao: true,
        paraQuemComunicado: true,
        tituloComunicado: true,
        mensagemComunicado: true
      },
      loadingInputContent: false,
      options: {
        publicos: [
          // { label: 'Aluno', value: 'aluno' },
          // { label: 'Responsável', value: 'responsavel' },
          // { label: 'Professor', value: 'professor' },
          // { label: 'Colaborador', value: 'colaborador' },
        ],
        categorias: [
          { label: 'Ativação Alunos', value: 'ativacao_alunos' },
          { label: 'Ativação Responsáveis', value: 'ativacao_responsaveis' },
          { label: 'Pedagógico', value: 'pedagogico' },
          { label: 'Contrato', value: 'contrato' },
          { label: 'Régua de Cobrança', value: 'regua_cobranca' }
        ],
        plataformas: [
          { label: 'App', value: 'App' },
          { label: 'E-mail', value: 'Email' },
          { label: 'Whatsapp', value: 'Blip' },
          // { label: 'SMS', value: 'SMS' },
          { label: 'Atividades', value: 'Atendimento' },
          // { label: 'AgendaEdu - Notificação', value: 'AgendaEduNotificacao' },
          // { label: 'AgendaEdu - Comunicado', value: 'AgendaEduComunicado' }
        ],
        regras: [
          { label: 'Igual', value: '=' },
          { label: 'Diferente	', value: '!=' },
          { label: 'Maior	', value: '>' },
          { label: 'Maior e igual	', value: '>=' },
          { label: 'Menor	', value: '<' },
          { label: 'Menor e igual	', value: '<=' },
          { label: 'Contém', value: 'like' },
          { label: 'Não Contém', value: 'not like' }
        ],
        tiposMensagemApp: [
          { label: 'Comunicado', value: 'COMUNICADO' },
          { label: 'Notícia', value: 'NOTICIA' },
          { label: 'Portifólio', value: 'PORTIFOLIO' },
          { label: 'Ocorrência', value: 'OCORRENCIA' },
          { label: 'Chegada', value: 'CHEGADA' },
          { label: 'Saída', value: 'SAIDA' }
        ],
        recorrencias: [
          { label: 'Diário', value: 'diario' },
          { label: 'Semanal', value: 'semanal' },
          { label: 'Mensal', value: 'mensal' }
        ],
        diaSemana: [
          { label: 'Segunda-feira', value: '1' },
          { label: 'Terça-feira', value: '2' },
          { label: 'Quarta-feira', value: '3' },
          { label: 'Quinta-feira', value: '4' },
          { label: 'Sexta-feira', value: '5' },
          { label: 'Sábado', value: '6' },
          { label: 'Domingo', value: '0' }
        ],
        databases: [
          { label: 'Apollo', value: 'mysql' },
          { label: 'Data Warehouse', value: 'dataWarehouse' }
        ],
        categoriasNotificacoes: [
          {
            label: "Frequência",
            value: "frequency"
          },
          {
            label: "Entrada/Saída",
            value: "gate"
          },
          {
            label: "Cobrança",
            value: "charging"
          },
          {
            label: "Ocorrência",
            value: "reports"
          },
          {
            label: "Alerta",
            value: "warnings"
          },
          {
            label: "Boletim",
            value: "report_card", 
          },
          {
            label: "Notas",
            value: "grades"
          },
          {
            label: "Presença/Falta",
            value: "absences"
          },
          {
            label: "Material Didático",
            value: "study_material"
          },
          {
            label: "Biblioteca",
            value: "library"
          }
        ],
        paraQuemComunicado: [
          { label: 'Alunos', value: 'students' },
          { label: 'Responsáveis', value: 'responsibles' },
          { label: 'Ambos', value: 'both' }
        ],
        comunicadoCriadoPara: [
          { label: 'Alunos', value: 'students' },
          { label: 'Turmas', value: 'classrooms' }
        ],
        analiseIntegrada: [],
        categoriasAtendimento: [],
        canaisAtendimento: [],
        unidades: [],
        wpTemplates: [],
        categoriasComunicados: []
      },
      wait: {
        analiseIntegrada: false,
        categoriaAtendimento: false,
        canalAtendimento: false,
        unidades: false,
        wpTemplates: false,
        // categoriasComunicados: false
      },
      cmOptions: {
        lineNumbers: true,
        mode: 'sql',      // Optional, for better editor clarity
        theme: 'default',
        indentWithTabs: true,
        tabSize: 2,
        autofocus: true
      }
    }
  },
  mounted () {
    window.spinner.emit("open")

    this.createBkp()
    this.getAnaliseIntegradaFields()
    this.getCategoriasAtendimento()
    this.getCanaisAtendimento()
    this.getUnidades()
    this.getWpTemplates()
    // this.getCategoriasComunicados()
  },
  methods: {
    sql () {
      return sql()
    },
    getAnaliseIntegradaFields () {
      FerramentasService.getAnaliseIntegradaFields().then(
        response => {
          console.log("response:", response)

          if ( response.data.length > 0 ) {
            const campos = []
            const data = response.data.sort()
            data.map( k => {
              campos.push({label: k, value: k})
            })

            this.options.analiseIntegrada = campos
            
          } else {
            window.toaster.emit('open', {style: "warning", message: "Não encontramos gatilhos.", floater: true})
          }
          this.wait.analiseIntegrada = true
          this.finishGetData()
        },
        error => {
          console.log("error:", error)
          window.spinner.emit("close")
          window.toaster.emit('open', {style: "warning", message: "Ops. Algo aconteceu. Não conseguimos encontrar os campos da análise integrada no momento. Tente novamente, se o erro persistir, procure o suporte.", floater: true})
        }
      )
    },
    getCategoriasAtendimento () {
      AtendimentoService.getCategoriasAtendimento().then(
        response => {
          console.log("categorias:", response.data)
          this.options.categoriasAtendimento = createSelectOptions(response.data,'idAtividadeCategoria','nome')

          this.wait.categoriaAtendimento = true
          this.finishGetData()
        },
        error => {
          console.log("error:", error)
          window.spinner.emit("close")
          window.toaster.emit('open', {style: "warning", message: "Ops. Algo aconteceu. Não conseguimos encontrar categorias de atendimento no momento. Tente novamente, se o erro persistir, procure o suporte.", floater: true})
        }
      )
    },
    getCanaisAtendimento () {
      AtendimentoService.getCanaisAtendimento().then(
        response => {
          console.log("getCanaisAtendimento:", response)
          if ( response.data.length > 0 ) {
            this.options.canaisAtendimento = createSelectOptions(response.data,'idAtendimentoCanal', 'nome')
          } else {
            window.toaster.emit('open', {style: "warning", message: "Não encontramos gatilhos.", floater: true})
          }
          this.wait.canalAtendimento = true
          this.finishGetData()
        },
        error => {
          console.log("error:", error)
          window.spinner.emit("close")
          window.toaster.emit('open', {style: "warning", message: "Ops. Algo aconteceu. Não conseguimos encontrar categorias de atendimento no momento. Tente novamente, se o erro persistir, procure o suporte.", floater: true})
        }
      )
    },
    getUnidades () {
      UserService.getUnidades().then(
        response => {
          console.log("getUnidades:", response)
          if ( response.data.length > 0 ) {
            response.data.map( k => {
              k.label = k.sigla + " - " + k.descricao
            })
            this.options.unidades = createSelectOptions(response.data,'idUnidade', 'label')
          } else {
            window.toaster.emit('open', {style: "warning", message: "Não encontramos unidades.", floater: true})
          }

          this.wait.unidades = true
          this.finishGetData()
        },
        error => {
          console.log("error:", error)
          window.spinner.emit("close")
          window.toaster.emit('open', {style: "warning", message: "Ops. Algo aconteceu. Não conseguimos encontrar unidades no momento. Tente novamente, se o erro persistir, procure o suporte.", floater: true})
        }
      )
    },
    getWpTemplates () {
      FerramentasService.getRDConversasTemplate().then(
        response => {
          console.log("getRDConversasTemplate:", response)
          if ( response.data.length > 0 ) {
            this.options.wpTemplates = response.data
          } else {
            window.toaster.emit('open', {style: "warning", message: "Não encontramos templates do RD Conversas.", floater: true})
          }

          this.wait.wpTemplates = true
          this.finishGetData()
        },
        error => {
          console.log("error:", error)
          window.spinner.emit("close")
          window.toaster.emit('open', {style: "warning", message: "Ops. Algo aconteceu. Não conseguimos encontrar templates do RD Conversas no momento. Tente novamente, se o erro persistir, procure o suporte.", floater: true})
        }
      )
    },
    getCategoriasComunicados () {
      AgendaEduService.getCategoriasComunicados().then(
        response => {
          console.log("getCategoriasComunicados:", response.data)
          const categorias = response.data.data.map(({ id, type, attributes: { name }} ) => ({ id, type, name }) )
          this.options.categoriasComunicados = createSelectOptions(categorias, 'id', 'name')

          this.wait.categoriasComunicados = true
          this.finishGetData()
        },
        error => {
          console.error(error)
        }
      )
    },
    finishGetData () {
      const finished = !Object.values(this.wait).includes(false)

      if ( finished ) {
        window.spinner.emit("close")
      }
    },
    addCapaComunicado ( e ) {
      console.log(e)
    },
    getNomePlataforma () {
      // console.log("this.gatilho.plataformaDisparo:", this.gatilho.plataformaDisparo)
      const nome = this.options.plataformas.filter( k => {
        // console.log("k:", k)

        return k.value == this.gatilho.plataformaDisparo
      })[0].label
      // console.log("nome:", nome)

      return nome
    },
    addCountryCode ( src, e ) {
      var target = this.gatilho[src]
      const iniCode = '+55'
      // console.log("key:", e.key)
      const escape = ['Enter', 'Backspace', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Tab', 'Escape']
      const allowAddCode = !escape.includes(e.key)


      // console.log("target.length:", target.length)
      
      if ( !target && allowAddCode ) {
        const newValue = iniCode + target
        console.log("newValue:", newValue)

        this.gatilho[src] = newValue
      }
    },
    createBkp () {
      this.bkp = this.gatilho
    },
    editGatilho ( gatilho ) {
      if ( !gatilho.templateVars ) {
        gatilho['templateVars'] = []
      }
      this.gatilho = gatilho
    },
    updateExpressao ( value ) {
      this.gatilho.expressao = value
    },
    validateSql () {
      if ( this.gatilho.expressao ) {
        this.gatilho.expressao = this.gatilho.expressao.replace(/"/g,"'")
      }
    },
    limitDay () {
      if ( this.gatilho.diaMes < 1 ) this.gatilho.diaMes = 1
      if ( this.gatilho.diaMes > 31 ) this.gatilho.diaMes = 31
    },
    createTemplateVarsInputs () {
      const selectedTemplate = this.options.wpTemplates.filter( a => a.id == this.gatilho.template)[0].content

      console.log("selectedTemplate:", selectedTemplate)
      const matches = selectedTemplate.match(/{{(\d+)}}/g) || []
      
      console.log("matches:", matches)
      // Populate the placeholders array with an object for each placeholder
      this.gatilho.templateVars = matches.map(match => ({
        key: match.match(/{{(\d+)}}/)[1],
        value: ''
      }))


      console.log("templateVars:", this.templateVars)
    },
    validate () {
      this.gatilho.testeTelefone = this.gatilho.testeTelefone ? clearMask('phonecc',this.gatilho.testeTelefone) : null
      // this.validateSql()

      delete this.gatilho.loading
      delete this.gatilho.intervencoes
      delete this.gatilho.message
      delete this.gatilho.idAtividadeCategoria

      // var process = this.gatilho.expressao.replace(/\s+/g, ' ').trim()
      var process = this.gatilho.expressao.trim()
      this.gatilho.expressao = process

      const valid = FormService.validate(this.$refs.gatilhoForm, this.valid)
      return valid.response
    },
    save () {
      const valid = this.validate()
      
      if ( valid ) {
        window.spinner.emit("open")
        
        this.$emit('save', this.gatilho)
      } else {
        window.toaster.emit('open', {style: "warning", message: "Dados Incorretos. Valide o formulário e tente novamente.", floater: true})
      }

    },
    cancel () {
      this.$emit("close")
    }
  }
}
</script>

<style lang="scss" scoped>
  
  .frame {
    padding: $mg
  }

  .form-wrap {
    header {
      padding: $hmg_mid;
      border-bottom: $border-component;

      h3 {
        margin: 0; padding: 0;
      }
      p {
        margin: 0;
      }
    }
    
    >div {
      .inner-content {
        padding: $hmg_mid;
        border-bottom: $border-component;

        &:last-of-type {
          border-bottom: 0;
        }
      }
    }
  }

  .gatilhoForm {
    h4 {
      padding: $hmg_small 0;
      @extend %transition_4e
    }
    h5 {
      padding: $hmg_small;
      @extend %transition_4e
    }

    .inner-content {
      padding-top: $hmg_mid; margin-top: $hmg_mid;
      border-top: 1px solid $color-secondary;
      @extend %transition_4e;

      >div {
        @extend %transition_4e
      }
    }
  }

  .form-actions {
    justify-content: flex-end;
  }

  .templateSelect {
    display: block;
    padding: $hmg_small;
    border-radius: $border-radius-large;
    border: $border-component;

    ul {
      width: 100%; height: 40vh;
      padding: 0 $mg_mini 0 0; margin: 0;
      align-items: flex-start; align-content: flex-start;
      overflow: auto;
      @extend %scrollbar_card;

      .tsCheck {
        width: 24px; height: 24px;
        border-radius: 100%;
        background-color: $color-bg;
        border: $border-component;
        position: absolute;
        right: $mg_mini; top: $mg_mini;
        z-index: 200;
        display: flex; align-content: center;
        align-items: center; justify-content: center;
      }

      li { 
        list-style: none; padding: 0; margin: 0;
        width: 100%;

        label {
          width: 100%;
          padding: 0; margin: 0;
          position: relative;

          p {
            margin: 0; padding: 0 $mg_mid 0 0;
          }
        }
      }
    }
    
  }

  .code {
    border: $border-component;
    border-radius: $border-radius !important;
    overflow: hidden;
  }

  .templateVars {
    display: flex; align-items: center;
    padding: 0; margin: 0;
    gap: $hmg_mid;

    li {
      padding: 0; margin: 0;
      flex: 1; max-width: 33.33333%;
      list-style: none;
    }
  }
</style>
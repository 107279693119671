<template>
  <controller lockScroll>
    <transition name="fadein">
      <iframe
        v-if="src"
        :src="src"
        frameborder="0"
        allowtransparency="true"
        ref="iframe"
        :onload="finishLoading"
      ></iframe>
      <div
        v-else
        class="pbi"
        id="powerBIcontainer"
      >
    </div>
    </transition>
  </controller>
</template>

<script>
import moment from 'moment'
import PbiService from '@/services/pbi.service.js'
import * as pbi from 'powerbi-client'

export default {
  props: {
    id: {
      type: [String, Number]
    },
    hash: {
      type: [String, Number]
    }
  },
  watch: {
    id ( value ) {
      if ( value ) {
        this.src = null
        
        if ( this.id == 'pbi' ) {
          this.getPowerBi()
        } else {
          this.handleSrc()
        }
      }
    }
  },
  data () {
    return {
      src: null,
      pbiURI: 'https://app.fabric.microsoft.com/view?r=',
      payload: {
        resource: null,
        params: null,
        exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
      },
      pbi: null,
      pbiConfig: {
        id: null,
        token: null,
        dataset: null
      },
      pbiScope: {
        type: "report",
        tokenType: null,
        id: null,
        accessToken: null,
        embedUrl: null,
        permissions: null,
        settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: false
        }
      },
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
    }
  },
  mounted () {
    window.spinner.emit("open")
    this.src = null
    if ( this.id == 'pbi' ) {
      this.getPowerBi()
    } else {
      this.handleSrc()
    }
    
    // console.log("models:", models)
  },
  methods: {
    getPowerBi () {
      if ( this.id == 'pbi' && this.hash ) {
        PbiService.getPainel(this.hash).then(
          response => {
            console.log('getPainel:', response)

            this.pbiConfig.id = response.data.report
            this.pbiConfig.dataset = response.data.dataset
            this.pbiConfig.token = response.data.token
            
            setTimeout(() => {
              this.runPbi()
            },200)
          },
          error => {
            console.error(error)
          }
        )
      }
    },
    handleSrc () {
      console.log("handleSrc - id:", this.id)

      if ( this.id == 'pbi' && this.hash ) {
        
        let powerbi = new pbi.service.Service(
            pbi.factories.hpmFactory,
            pbi.factories.wpmpFactory,
            pbi.factories.routerFactory
        )
        
        const self = this

        console.log("handleSrc - pbiConfig", this.pbiConfig)

        setTimeout(() => {
          var reportContainer = document.getElementById("powerBIcontainer")
          var painel = powerbi.embed(reportContainer, this.pbiConfig)

          painel.on("loaded", function() {
              console.log("loaded...")
              self.finishLoading()
          })
        },500)
        

        // this.src = this.pbiURI + this.hash
        // PbiService.getPainel(this.hash).then(
        //   response => {
        //     console.log('getPainel:', response)
        //     const { report } = response.data
        //     this.pbiConfig.id = report
        //     // this.pbiConfig.accessToken = token

        //     this.pbi = true
        //     // this.pbiConfig.id = report
        //     // PbiService.dropPainel(token, group, report).then(
        //     //   response => {
        //     //     console.log('dropPainel:', response)
        //     //     // this.pbi = response.data
                
        //     //     this.pbiConfig.embedUrl
        //     //     // console.log(' this.$refs.pbi:',  this.$refs.pbi)

        //     //     // const pbi = this.$refs.pbi.contentDocument || this.$refs.pbi.contentWindow.document
        //     //     // console.log('pbi:', pbi)

        //     //     // if (pbi) {
        //     //     //   pbi.body.insertAdjacentHTML('beforeend', response.data)
        //     //     //   // this.finishLoading()
        //     //     // }
        //     //   },
        //     //   error => {
        //     //     console.error(error)
        //     //   }
        //     // )
        //   },
        //   error => {
        //     console.error(error)
        //   }
        // )
      } else {
        var sign = require('jwt-encode')

        const parsed = this.id.split('-')
        const type = parsed[0]
        const dashboard = parseFloat(parsed[1])

        this.payload.resource = { [type]: dashboard }
        const params = this.$route.query.path
        console.log("params:", params)

        this.payload.params = params ? this.getQueryStringParams(params) : {}
        
        console.log("payload:", this.payload)
        
        this.src = process.env.VUE_APP_METABASE_SITE_URL + "/embed/" + type + '/' + sign(this.payload, process.env.VUE_APP_METABASE_SECRET_KEY) + "#bordered=false&titled=true"
      }
      
      
    },
    runPbi () {
      // const { report, token, group } = data
      var permissions = pbi.models.Permissions.All
      
      console.log('user:', this.currentUser)
      const unidades = this.currentUser.unidades.flatMap( unidade => `'${unidade.label}'` ).toString()
      console.log('unidades:', unidades)
      
      this.pbiScope.tokenType = pbi.models.TokenType.Embed
      this.pbiScope.id = this.pbiConfig.id
      this.pbiScope.accessToken = this.pbiConfig.token
      this.pbiScope.embedUrl =  `https://app.powerbi.com/reportEmbed?reportId=${ this.pbiConfig.id }&groupId=${ this.pbiConfig.dataset }&filter=unidade/unidade in (${ unidades })`
      this.pbiScope.permissions = permissions


      console.log("pbiConfig:", this.pbiScope)

      let powerbi = new pbi.service.Service(
          pbi.factories.hpmFactory,
          pbi.factories.wpmpFactory,
          pbi.factories.routerFactory
      )
      
      const self = this

      setTimeout(() => {
          var reportContainer = document.getElementById("powerBIcontainer")
          var painel = powerbi.embed(reportContainer, this.pbiScope)

          painel.on("loaded", function() {
              console.log("loaded...")
              self.finishLoading()
          })
        },300)

      // this.pbiConfig.accessToken = token
    },
    finishLoading () {      
      setTimeout (() => {
        window.spinner.emit("close")
        this.loading = false
      },1000)
    },
    getQueryStringParams(query) {      
      var array = query.split('&')
      var result = {}

      console.log("getQueryStringParams:", array)
      // var common = false

      array.map( k => {
        if ( k.includes('%') ) {
          if(k == 'unidade=%unidade') {
            result['unidade'] = this.currentUser.colaborador.sigla
            // result.push({"unidade": this.currentUser.colaborador.sigla})
          }
          if(k == 'email=%email') {
            result['email'] = this.currentUser.colaborador.email
            // result.push({"email": this.currentUser.colaborador.email})
          }
          if(k == 'nome_pessoa=%nome_pessoa') {
            result['nome_pessoa'] = this.currentUser.colaborador.nome
            // result.push({"nome_pessoa": this.currentUser.colaborador.nome})
          }
          if(k == 'data_atual=%data_atual') {
            result['data_atual'] = moment().format('YYYY-MM-DD')
            // result.push({"data_atual": moment().format('YYYY-MM-DD')})
          }

          // common = true
        } else if ( k != "" ) {
          var data = k.split(";")
          data.map( k => {
            const item = k.split("=")

            result[item[0]] = item[1]
          })

          // result = drop
        }

        console.log("result:", result)
        // console.log("common:", common)

      })

      return result
    }
  }
}
</script>

<style lang="scss">
  iframe, .pbi {
    width: 100%; height: calc(100vh - 62px);
    overflow-y: auto;
    border: none; outline: 0;
    background: transparent;

    &.pbi {
      overflow: hidden;
    }
  }
</style>
<template>
  <section class="boletos">
    <header v-if="!this.matricula">
      <h2>Boletos</h2>
    </header>
    <form-spinner v-if="loading"/>
    <toaster/>
    <modal ref="modal">
      <input-spinner class="detailsSpinner" v-if="processing" />
      <div class="retorno row no-padd" v-else-if="retorno">
        <div class="col-md-12">
          <template v-if="retornoSucesso">
            <success></success>
            <h2 class="color-text">Tudo certo!</h2>
            <p v-if="atualizar">Boleto atualizado com sucesso!<br> Lembramos que seu boleto estará disponível após o registro bancário, que acontece em até 48h.</p>
            <p v-if="pagarCartao">Pagamento com cartão efetuado com sucesso!</p>
          </template>
          <template v-if="retornoErro">
            <fa :icon="['fal','face-meh']" class="icon"></fa>
            <h2 class="color-text">Ops. Algo deu errado.</h2>
            <p v-if="atualizar">Infelizmento não conseguimos atualizar seu boleto. Tente novamente. Se o erro persistir, procure nosso atendimento.</p>
            <p v-if="pagarCartao">Infelizmente não conseguimos processar seu pagamento.</p>
          </template>
          <div class="form-actions">
            <a href="#" class="btn btn-primary" @click.prevent="recallPagarComCartao()" v-if="pagarCartao && retornoErro">Tentar novamente</a>
            <a href="#" class="btn btn-primary" @click.prevent="recallAtualizarBoleto()" v-if="atualizar && retornoErro">Tentar novamente</a>
            <a href="#" class="btn btn-secondary" @click.prevent="closeModal()" v-if="retornoErro">Cancelar</a>
            <a href="#" class="btn btn-primary" @click.prevent="closeModal(); getBoletos()" v-if="atualizar && retornoErro || retornoSucesso">Voltar para página</a>
          </div>
        </div>
      </div>
      <div v-else-if="codigoPix">
        <div class="pixModal">
          <div>
            <span class="codeWrap" @click.prevent="copyCode(codigoPix)">{{ codigoPix }}</span>
            <p>Esta parcela sera baixada automaticamente após a confirmação do pagamento.</p>
          </div>
          <footer>
            <a href="#" class="btn btn-primary" @click.prevent="clearPix(true)">Fechar</a>
          </footer>
        </div>
      </div>
      <div v-else>
        <div class="row no-padd" v-if="atualizar">
          <div class="col-md-12 form-group no-padd-left text-center" ref="atualizarForm">
            <h6 class="color-text" v-if="datasDisponiveis.length == 0">Não existem datas disponíveis para atualização deste boleto.</h6>
            <template v-else>
              <p>Escolha uma das datas disponíveis</p>
              <select-control name="datasDisponiveis" :options="datasDisponiveis" v-model="dataAtualizacao" placeholder="Selecione" cssClass="invert" :valid="validAtualizacao.datasDisponiveis" required/>
            </template>
          </div>
        </div>
        <div class="row no-padd" v-if="pagarCartao" ref="cardForm">
          <div class="form-group col-md-12 no-padd-left">
            <input-control type="text" placeholder="Número" v-model="cartao.numero" name="numero" :valid="validCartao.numero" @keyup="validCartao.numero = true" cssClass="invert" required/>
          </div>
          <div class="form-group col-md-12 no-padd-left">
            <input-control type="text" placeholder="Nome" v-model="cartao.nome" name="nome" :valid="validCartao.nome" @keyup="validCartao.nome = true" cssClass="invert" required/>
          </div>
          <div class="form-group col-6 no-padd-left">
            <input-control type="text" placeholder="Validade" v-model="cartao.validade" masking="validade_cartao" name="validade" :valid="validCartao.validade" @keyup="validCartao.validade = true; checkValidationDate" cssClass="invert" required/>
          </div>
          <div class="form-group col-6 no-padd-left">
            <input-control type="text" placeholder="CVV" v-model="cartao.cvv" masking="cvv" name="cvv" :valid="validCartao.cvv" @keyup="validCartao.cvv = true" cssClass="invert" required/>
          </div>
        </div>
        <div class="row no-padd" v-if="pagarPix">
          <!-- <div class="form-group col-md-12 no-padd-left text-center">
            <p>Para pagar seu boleto com PIX, basta gerar o código</p>
            <a href="#" class="btn btn-primary" @click.prevent="pagarComPix()">Gerar código PIX</a>
            <input-control type="text" placeholder="Número" v-model="cartao.numero" name="numero" :valid="validCartao.numero" @keyup="validCartao.numero = true" cssClass="invert" required/>
          </div> -->
          <!-- <div class="form-group col-md-12 no-padd-left">
            <input-control type="text" placeholder="Nome" v-model="cartao.nome" name="nome" :valid="validCartao.nome" @keyup="validCartao.nome = true" cssClass="invert" required/>
          </div>
          <div class="form-group col-6 no-padd-left">
            <input-control type="text" placeholder="Validade" v-model="cartao.validade" masking="validade_cartao" name="validade" :valid="validCartao.validade" @keyup="validCartao.validade = true; checkValidationDate" cssClass="invert" required/>
          </div>
          <div class="form-group col-6 no-padd-left">
            <input-control type="text" placeholder="CVV" v-model="cartao.cvv" masking="cvv" name="cvv" :valid="validCartao.cvv" @keyup="validCartao.cvv = true" cssClass="invert" required/>
          </div> -->
        </div>
        <div class="form-actions">
          <a href="#" class="btn btn-primary" @click.prevent="atualizarBoleto()" v-if="atualizar">Atualizar</a>
          <a href="#" class="btn btn-primary" @click.prevent="pagarComCartao()" v-if="pagarCartao">Pagar</a>
          <a href="#" class="btn btn-secondary" @click.prevent="closeModal()">Cancelar</a>
        </div>
      </div>
    </modal>
    <div class="pageBody" v-if="loaded && boletos.length > 0">
      <div class="pre" v-if="!this.matricula">
        <h5 class="color-text">Olá, {{ nome }}</h5>
        <p>Seja bem-vindo. Aqui você encontra todos os seus boletos.</p>
      </div>
      <div class="boletosDetails">
        <ul>
          <li>
            <h1 class="color-text">{{ totais.boletos }}</h1>
            <p>Boletos</p>
          </li>
          <li>
            <h1 class="color-text">{{ totais.vencidos }}</h1>
            <p>Vencidos</p>
          </li>
          <li>
            <h1 class="color-text">{{ totais.ren }}</h1>
            <p>Renegociação</p>
          </li>
        </ul>
      </div>
      <div>
        <table class="content-table large" v-if="!loading">
          <thead>
            <template v-if="!isMobile">
            <tr>
              <th>Produto</th>
              <th>Aluno</th>
              <th>Vencimento</th>
              <th>Status</th>
              <th class="text-right">Valor</th>
              <th class="act"></th>
            </tr>
          </template>
          <template v-else>
            <tr v-for="boleto in boletos" :key="boleto">
              <th >Produto</th>
              <th>Aluno</th>
              <th>Vencimento</th>
              <th>Status</th>
              <th class="text-right">Valor</th>
              <th class="act"></th>
            </tr>
          </template>
          </thead>
          <tbody>
            <tr v-for="(boleto,i) in boletos" :key="boleto">
              <td>{{ boleto.produtos }} {{ boleto.MENGrupoExclusiva && matricula ? "(" + boleto.MENGrupoExclusiva + ")" : '' }}</td>
              <td>{{ boleto.aluno[0].nome }}</td>
              <td>{{ boleto.vencimento }}</td>
              <td><span :class="{ alert: boleto.status == 'Vencido' }">{{ boleto.status }}</span></td>
              <td class="text-right">{{ formatMoney( boleto.valor ) }}</td>
              <td class="act">
                <!-- <a :href="getUrlBoleto(boleto)" v-if="boleto.origem == 'perseus'">
                  <fa :icon="['fas', 'rectangle-barcode']" class="icon"></fa>
                </a> -->
                <mini-spinner v-if="boleto.waiting" />
                <template v-else>
                  <a :href="boleto.boletourl" target="_blank" v-if="boleto.origem == 'pagarMeRen' || boleto.origem == 'pagarMe' ">
                    <fa :icon="['fas', 'rectangle-barcode']" class="icon"></fa>
                  </a>
                  <a href="#" target="_blank" @click.prevent="getBoletoPerseus(boleto.transacao,i)" v-else>
                    <fa :icon="['fas', 'rectangle-barcode']" class="icon"></fa>
                  </a>
                  <!-- <a class="hide" href="#" target="_blank" download="boleto" :ref="'boletoPerseus_' + i ">
                    <fa :icon="['fas', 'rectangle-barcode']" class="icon"></fa>
                  </a> -->
                  <template v-if="boleto.status === 'Vencido' && boleto.origem && boleto.transacao && boleto.origem != 'pagarMeRen' && boleto.origem != 'perseusRen' ">
                    <a href="#" target="_blank" @click.prevent="getAtualizarBoleto( boleto )" >
                      <fa :icon="['fas', 'calendar-pen']" class="icon"></fa>
                    </a>
                    <a href="#" target="_blank" @click.prevent="getPagarComPix( boleto )">
                      <fa :icon="['fas', 'qrcode']" class="icon"></fa>
                    </a>
                    <a href="#" target="_blank" @click.prevent="getPagarComCartao( boleto )">
                      <fa :icon="['fas', 'credit-card']" class="icon"></fa>
                    </a>
                  </template>
              </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="pageBody" v-if="loaded && boletos.length == 0">
      <div class="empty">
        <fa :icon="['fal','face-smile']" class="icon"></fa>
        <h2 class="color-text">Ufa! Você não tem boletos pendentes.</h2>
      </div>
    </div>
    <confirm 
      :backdrop="false"
      class="confirm"
      ref="confirm"
    ></confirm>
    <modal ref="barcode">
      <section class="barcodeModal">
        <div>
          <h3>Código de barras copiado!</h3>
          <p>O arquivo deste boleto não está disponível, mas não se preocupe, copiamos o código de barras para sua área de transferência.</p>
          <span v-if="currentBarcode">{{ currentBarcode }}</span>
        </div>
        <footer>
          <a href="#" @click.prevent="$refs.barcode.close()" class="btn btn-primary">Fechar</a>
        </footer>
      </section>
    </modal>
  </section>
</template>

<script>
import UserService from '@/services/user.service'
import FormService from '@/services/form.service'
import { formatDate, formatMoney, createSelectOptions, checkValidationDate } from "@/services/utils.service"
import Modal from '@/components/utilities/Modal'
import InputSpinner from '@/components/utilities/InputSpinner'
import Success from '@/components/utilities/Success'
import MiniSpinner from '@/components/utilities/MiniSpinner.vue'
import FormSpinner from  '@/components/utilities/FormSpinner.vue'

export default {
  name: 'Boletos',
  components: {
    Modal, InputSpinner, Success, MiniSpinner, FormSpinner
  },
  props: {
    matricula: {
      type: Number
    },
    gestao: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      agruparBoletos: false,
      nome: 'Visitante',
      boletos: [],
      isMobile: false,
      empty: false,
      loading: false,
      loaded: false,
      slideLoop: null,
      atualizar: false,
      processing: false,
      datasDisponiveis: [],
      dataAtualizacao: null,
      transacaoParaAtualizar: null,
      validAtualizacao: {
        dataAtualizacao: true
      },
      cartao: {
        numero: null,
        nome: null,
        validade: null,
        cvv: null
      },
      validCartao: {
        numero: true,
        nome: true,
        validade: true,
        cvv: true
      },
      pagarCartao: false,
      pagarPix: false,
      retorno: false,
      retornoSucesso: false,
      retornoErro: false,
      totais: {
        boletos: 0,
        vencidos: 0,
        ren: 0
      },
      codigoPix: null,
      currentBarcode: null
    }
  },
  mounted () {
    this.getBoletos()
    this.checkSize()

    window.addEventListener('resize', this.checkSize)
   
   if ( this.currentUser ) {
    //  console.log("currentUser:", this.currentUser)
     this.nome = this.currentUser.nome
   }
  },
  computed: {
    currentUser() {
      return localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null
    }
  },
  methods: {
    getBoletos () {
      window.spinner.emit("open")
      this.loading = true
      this.loaded = false
      // console.log("getBoletos.matricula:", this.matricula)

      UserService.getBoletos(this.matricula).then(
        response => {
          // console.log("response:", response)
          if ( Object.keys(response.data.unidades).length > 0 ) {
            
            // let unidades = response.data.unidades;
            // unidades.forEach((value) => {
            //   if (value.agruparBoletos != 'N') {
            //     this.agruparBoletos = true
            //   }
            // });
          }

          if ( Object.keys(response.data.boletos).length > 0 ) {
            this.boletos = Object.values(response.data.boletos)

            this.boletos.map( k => {
              // console.log("boletos:", k)
              if ( k.produto.length > 1 ) {
                var produtos = []
                k.produto.map( kp => {
                  produtos.push(kp.nome)
                })
                // console.log("produtos:", produtos)
                k.produtos = produtos.join(", ")
              } else {
                k.produtos = k.produto[0].nome
              }
            })

            this.getTotais()
            // console.log("boletos:", this.boletos)
          } else {
            this.empty = true
          }

          window.spinner.emit("close")
          this.loading = false
          this.loaded = true
        },
        error => {
          console.error(error.response.message)
          window.spinner.emit("close")
          this.loading = false
        }
      )
    },
    checkSize () {
      if ( this.slideLoop ) clearInterval(this.slideLoop)

      this.slideLoop = setTimeout(() => {
        this.isMobile = window.innerWidth <= 1024
      }, 200)
    },
    formatDate ( value ) {
      return formatDate(value)
    },
    formatMoney ( value ) {
      return formatMoney(value)
    },
    getBoletoPerseus ( menCodigo, i ) {
      console.log("getBoletoPerseus:", menCodigo)
      this.boletos[i].waiting = true
      UserService.getUrlBoletoPerseus(menCodigo).then(
        response => {
          const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[^\s]*)?$/i;
          if ( urlPattern.test(response.data) ) {
            window.open(response.data)
          } else {
            const barcode = response.data.replace(/'/g, "")
            
            this.currentBarcode = barcode
            console.log('barcode:', this.currentBarcode)

            navigator.clipboard.writeText(barcode).then(() => {
              const options = {
                size: "mid",
                backdrop: true,
                backdropColor: "transparent",
                backdropClose: true,
                padding: 0,
                onClose: () => {
                  console.log("clear barcode...")
                  this.currentBarcode = null
                }
              }

              this.$refs.barcode.open(options)
            },() => {
              console.error('Failed to copy');
              /* Rejected - text failed to copy to the clipboard */
            })

          }

          this.boletos[i].waiting = false
          // console.log("response.data", response.data)
        },
        error => {
          console.error(error)
          this.boletos[i].waiting = false
        }
      )
    },
    getAtualizarBoleto ( boleto ) {

      if ( boleto.origem == "pagarMe" ) {
        this.atualizar = true
        this.transacaoParaAtualizar = boleto
          
        const options = {
          title: 'Atualizar boleto',
          titleAlign: "center",
          // message: "Se quiser uma mensagem...", //não obrigatório
          size: "mid", //small, mid, large, full
          backdrop: true,
          backdropColor: "transparent",  //se o backdrop for false, não precisa
          backdropClose: false, //se o backdrop for false, não precisa
          fullHeight: false
        }
        
        this.$refs.modal.open(options)
        this.processing = true
        this.getAtualizacaoDatas(boleto)
      } else {
        this.$refs.confirm.run({
          message: "Quer atualizar o boleto?",
          obs: "Copiamos o código de barras para área de transferência. Você será redirecionado para o site do banco para prosseguir com a atualização do seu boleto. Basta colar o código de barras no campo informado.",
          confirmButton: "Sim",
          denyButton: "Não",
          data: boleto,
          callback: this.atualizarBoletoBanco
        })
      }
    },
    fallbackCopy ( cod ) {
      // console.log("window.clipboardData:", window.clipboardData)
      const el = document.createElement('textarea')
      el.value = cod
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      
    },
    atualizarBoletoBanco ( response, data) {
      if ( response ) {
        // console.log("data.atualizarBoleto:", data.MENLinhaDigitavel)

        const barcode = data.MENLinhaDigitavel
        const itau = '341'
        const santander = '033'

        const bankCode = barcode.substr(0,3)

        if ( !navigator.clipboard ) {
          this.fallbackCopy(data.MENLinhaDigitavel)
        } else {
          navigator.clipboard.writeText(data.MENLinhaDigitavel).then(() => {
            // console.log('Content copied to clipboard');
            const eventData = {
              'event_category': 'atualiza_boleto',
              'event_label': this.currentUser.email
            }

            // console.log("eventData:",eventData)
            this.$gtag.event('atualiza_boleto', eventData)

            if ( bankCode == itau ) {
              window.open("https://www.itau.com.br/servicos/boletos/segunda-via")
            } else if ( bankCode == santander ) {
              window.open("https://www.santander.com.br/2-via-boleto")
            }
            /* Resolved - text copied to clipboard successfully */
          },() => {
            console.error('Failed to copy');
            /* Rejected - text failed to copy to the clipboard */
          });
        }
      }
    },
    atualizarBoleto () {
      const valid = FormService.validate(this.$refs.atualizarForm, this.validAtualizacao)
      
      if ( valid.response ) {
        const options = {
          tipo: this.transacaoParaAtualizar.origem,
          data: this.dataAtualizacao.replace(/\D/g, ''),
          transacao: this.transacaoParaAtualizar.transacao
        }

        this.processing = true
        UserService.atualizarBoleto( options ).then(
          response => {
            console.log('atualizarBoleto:',response.data)
            this.processing = false
            this.retorno = true
            this.retornoSucesso = true
            this.retornoErro = false
          },
          error => {
            console.error(error.response.message)
            this.processing = false
            this.retorno = true
            this.retornoErro = true
            this.retornoSucesso = false
            // window.toaster.emit('open', {style: "warning", message: "Não foi possível atualizar o boleto selecionado. Tente novamente mais tarde. Se o problema persistir entre em contato com nosso atendimento.", floater: true})
          }
        )
      }
    },
    recallAtualizarBoleto () {
      this.atualizar = true
      this.retorno = false
      this.retornoError = false
      this.retornoSucesso = false
    },
    getAtualizacaoDatas ( boleto ) {
      UserService.getAtualizacaoDatas( boleto ).then(
        response => {
          // console.log('getAtualizacaoDatas:',response.data)
          this.processing = false
          this.datasDisponiveis = createSelectOptions(response.data, 0, 0)
        },
        error => {
          console.error(error.response.message)
          this.processing = false
        }
      )
      
    },
    getPagarComCartao ( boleto ) {
      this.pagarCartao = true
      this.transacaoParaAtualizar = boleto
      

      const options = {
        title: 'Pagar com cartão',
        titleAlign: "center",
        // message: "Se quiser uma mensagem...", //não obrigatório
        size: "mid", //small, mid, large, full
        backdrop: true,
        backdropColor: "transparent",  //se o backdrop for false, não precisa
        backdropClose: false, //se o backdrop for false, não precisa
        fullHeight: false
      }
      
      this.$refs.modal.open(options)
      // this.getAtualizacaoDatas(boleto.transacao)
    },
    getPagarComPix ( boleto ) {
      // console.log("getPagarComPix:", boleto)
      this.pagarPix = true
      this.transacaoParaAtualizar = boleto

      this.$refs.confirm.run({
        message: "Tem certeza que você quer pagar esse boleto com pix?",
        confirmButton: "Sim",
        denyButton: "Não",
        callback: this.pagarComPix,
        backdropColor: 'transparent'
      })
    },
    pagarComPix ( state ) {
      if ( state ) {
        window.spinner.emit("open")
        const options = {
          origem: this.transacaoParaAtualizar.origem,
          transacao: this.transacaoParaAtualizar.transacao,
        }

        this.processing = true

        UserService.pagarComPix( options ).then(
          response => {
            // console.log('pagarComPix:',response.data)
            window.spinner.emit("close")
            this.processing = false
            // this.retorno = true
            // this.retornoSucesso = true
            // this.retornoErro = false
            this.codigoPix = response.data[1].qrCodePix

            const options = {
              title: 'Pagar com pix',
              titleAlign: "center",
              message: "Clique sobre o código para copiar.", //não obrigatório
              size: "mid", //small, mid, large, full
              backdrop: true,
              backdropColor: "transparent",  //se o backdrop for false, não precisa
              backdropClose: false, //se o backdrop for false, não precisa
              fullHeight: false
            }
            
            this.$refs.modal.open(options)
            // window.toaster.emit('open', {style: "success", message: "Tudo certo! Seu pagamento foi concluído", floater: true})
          },
          error => {
            console.error(error.response.message)
            this.processing = false
            this.retorno = true
            this.retornoSucesso = false
            this.retornoErro = true
            // window.toaster.emit('open', {style: "warning", message: "Não foi possível atualizar o boleto selecionado. Tente novamente mais tarde. Se o problema persistir entre em contato com nosso atendimento.", floater: true})
          }
        )
      }
    
    },
    copyCode ( code ) {
      navigator.clipboard.writeText(code).then(() => {
        window.toaster.emit('open', {style: "success", message: "Código pix copiado com sucesso.", floater: true})
      },() => {
        console.error('Ops! Não foi possível copiar o código pix selecionado.')
      })
    },
    clearPix ( closeModal ) {
      this.codigoPix = null

      if ( closeModal ) {
        this.$refs.modal.close()
      }
    },
    pagarComCartao () {
      const valid = FormService.validate(this.$refs.cardForm, this.validCartao)
      console.log("valid:", valid)
      if ( valid.response ) {
        const unidade = this.transacaoParaAtualizar.origem == "pagarMe" ? this.transacaoParaAtualizar.aluno[0].idUnidade : null

        const options = {
          origem: this.transacaoParaAtualizar.origem,
          idUnidade: unidade,
          transacao: this.transacaoParaAtualizar.transacao,
          idPessoa: this.currentUser.idPessoa,
          pagamento: {
            cartao: this.cartao
          }
        }

        this.processing = true
        UserService.pagarComCartao( options ).then(
          response => {
            console.log('pagarComCartao:',response.data)
            this.processing = false
            this.retorno = true
            this.retornoSucesso = true
            this.retornoErro = false
            // window.toaster.emit('open', {style: "success", message: "Tudo certo! Seu pagamento foi concluído", floater: true})
          },
          error => {
            console.error(error.response.message)
            this.processing = false
            this.retorno = true
            this.retornoSucesso = false
            this.retornoErro = true
            // window.toaster.emit('open', {style: "warning", message: "Não foi possível atualizar o boleto selecionado. Tente novamente mais tarde. Se o problema persistir entre em contato com nosso atendimento.", floater: true})
          }
        )
      }
    },
    recallPagarComCartao () {
      this.pagarCartao = true
      this.retorno = false
      this.retornoError = false
      this.retornoSucesso = false
    },
    closeModal () {
      this.atualizar = false
      this.pagarCartao = false
      this.retorno = false
      this.retornoError = false
      this.retornoSucesso = false
      this.transacaoParaAtualizar = null
      this.dataAtualizacao = null
      this.cartao = {
        numero: null,
        nome: null,
        validade: null,
        cvv: null
      }
      this.$refs.modal.close()
    },
    checkValidationDate (e) {
      checkValidationDate(e)
    },
    getTotais () {
      
      var vencidos = 0
      var ren = 0

      this.boletos.map( k => {
        console.log("k:", k)
        if ( k.status === 'Vencido' ) vencidos ++
        if ( k.origem == 'pagarMeRen' || k.origem == 'perseusRen' ) ren++
      })


      this.totais.boletos = this.boletos.length
      this.totais.vencidos = vencidos
      this.totais.ren = ren
    },
    getUrlBoleto ( boleto ) {
      // boleto.boleto ? boleto.boleto.boletourl : boleto.boletourl
      const token = "?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjY4ODAsImlzcyI6Imh0dHA6Ly9sb2NhbGhvc3Q6ODAwMC9hcGkvYXV0aC9sb2dpbiIsImlhdCI6MTU2MzQ3NzY4NywiZXhwIjoxNTYzNDgxMjg3LCJuYmYiOjE1NjM0Nzc2ODcsImp0aSI6ImRtRjZsYUMwWUswVTdzSVIifQ.ynkU2CQ2Kr3yt-cxA3o21y2wkg2HRj-zckdBYDy2MNE&email="

      return boleto.boletourl + token + this.currentUser.email
    },
    clickBoleto ( i ) {
      // const url = this.boletos[i].origem == 'perseus' ? this.boletos[i].boletourl + '/1' : this.boletos[i].boletourl
      const url = this.boletos[i].boletourl

      this.$refs['boletoPerseus_' + i][0].href = url
      this.$refs['boletoPerseus_' + i][0].click()
      this.$refs['boletoPerseus_' + i][0].href = "#"
    }
  }
}
</script>

<style lang="scss" scoped>
  .boletos {
    display: flex; flex-direction: column;
    align-items: stretch; align-content: stretch;
    background-color: $color-secondary;
    height: 100vh;
    // padding-bottom: $mg_extra
  }
  header {
    display: flex; width: 100%; height: 60px;
    align-content: center; align-items: center; justify-content: space-between;
    border-bottom: $border-component;
    padding: 0 $hmg;

    h2 {
      margin: 0; padding: 0;
    }

    // @media screen and (max-width: 1024px) {
    //   padding: 0 $hmg;
    // }
  }

  .detailsSpinner {
    margin: auto;
  }
  .pageBody {
    width: 100%;
    background-color: $color-secondary;
    flex: 1; padding: 0;
    overflow-y: auto;
    padding-bottom: $mg_large;
    
    > div:not(.boletosDetails) {
      padding: $hmg;
    }
    .pre {
      padding-bottom: 0 !important;
    }

    @extend %scrollbar_light;
  }

  .boletosDetails {
    display: block; position: relative;
    overflow-y: hidden; overflow-x: auto; height: 120px; width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar { 
      display: none;
    }

    ul {
      display: flex; width: auto; height: auto; align-content: center; align-items: flex-start;
      padding: $hmg_small; margin: 0;
      position: absolute; top: 50%; transform: translateY(-50%);
      left: 0;

      li {
        display: flex; flex-direction: column; align-content: flex-start; align-items: flex-start; justify-content: space-between;
        width: 120px; height: 80px;
        background-color: $color-bg;
        border-radius: $border-radius;
        margin-right: $hmg_small; padding: $hmg_small;
        list-style: none;

        h1,p { margin: 0; padding: 0; line-height: 1;}
        h1 {margin-bottom: 3px;}
      }
    }
  }

  .retorno {
    padding: $mg 0 $hmg;
    text-align: center;

    .icon {
      font-size: 64px; 
      margin-bottom: $hmg;
    }

    .form-actions {
      margin-top: $hmg
    }
  }

  tr td .alert {
    line-height: 1; padding: 4px 8px;
    background-color: $color-alert; color: $color-secondary;
    border-radius: 2em;
  }

  .empty {
    max-width: 320px;
    margin: $mg auto 0; padding: $hmg;
    text-align: center;

    .icon {
      font-size: 64px;
      margin-bottom: $hmg;
    }
  }

  .hide {
    display: none
  }

  .pixModal {
    >div {
      p {
        padding-top: $mg_mini;
      }
    }
    .codeWrap {
      display: block;
      padding: $mg_mini;
      border-radius: $border-radius;
      background-color: $color-bg;
      cursor: pointer;

    }

    footer {
      display: flex;
      justify-content: center;
      margin-top: $mg_mini
    }
  }

  .barcodeModal {
    >div {
      padding: $mg_mid;
      text-align: center;
      
      span {
        padding: $hmg_mid;
        border-radius: $border-radius;
        background-color: $color-bg;
        display: inline-block;
        margin: $hmg_mid auto 0
      }

      p {
        max-width: 90%; margin: auto;
        font-size: $font-size-large;
      }
    }
    footer {
      display: flex;
      padding: $hmg_mid; align-content: center;
      justify-content: flex-end;
      border-top: $border-component;
    }
  }
</style>